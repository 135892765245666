import { useState } from "react";
import { fetchTimeTableByZc } from "actions/timetableActions";
import { Typography } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import ModalSM from "components/ModalSM";
import DivInline from "components/DivInline";
import DeviceMongoModal from "components/DeviceMongoModal";
export default function TimeSettingDisplayModal({ children, zcObj }) {
  const [open, setOpen] = useState(false);
  const [timeObjAll, setTimeObjAll] = useState({});
  const handleOpen = async () => {
    setOpen(true);
    if (!zcObj) return;
    const objAll = await fetchTimeTableByZc(zcObj.zoneControlID);
    setTimeObjAll(objAll);
  };
  const timeStr = (arr) => {
    if (!arr) return "";
    if (arr[0] === 100) {
      return "Every ";
    } else if (arr?.length > 0) {
      return arr.join(", ");
    }
  };
  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={handleOpen}>
        {children}
      </div>
      {zcObj &&
        <ModalSM
          open={open}
          onClose={() => setOpen(false)}
          modalTitle={`Timer Setting for ${zcObj?.zoneControlID}`}
          height="90vh"
          width="80vw"
        >
          {(zcObj?.timeTableID1 || []).map((tid1, key) => {
            const tid2 = zcObj?.timeTableID2[key];
            const tid2Obj = timeObjAll?.[tid2] || {};
            const tid1Obj = timeObjAll?.[tid1] || {};
            return (
              <DivInline
                key={key}
                sx={{ width: "60vw", margin: "1vh" }}
                justifyContent="space-between"
              >
                <div
                  style={{
                    outline: "1px solid lightgrey",
                    padding: "1vh",
                    borderRadius: "1vh",
                  }}
                >
                  <DeviceMongoModal deviceObj={tid2Obj}>
                    <Typography variant="caption">{tid2}</Typography>
                  </DeviceMongoModal>
                  <Typography>Year:{timeStr(tid2Obj?.year)}</Typography>
                  <Typography>Month:{timeStr(tid2Obj?.month)}</Typography>
                  <Typography>Day:{timeStr(tid2Obj?.date)}</Typography>
                  <Typography>Weekday:{timeStr(tid2Obj?.isoWeekday)}</Typography>
                  <Typography>Hour:{timeStr(tid2Obj?.hour)}</Typography>
                  <Typography>Minute:{timeStr(tid2Obj?.minute)}</Typography>
                </div>
                <ArrowForward />
                <div
                  style={{
                    outline: "1px solid lightgrey",
                    padding: "1vh",
                    borderRadius: "1vh",
                  }}
                >
                  <DeviceMongoModal deviceObj={tid1Obj}>
                    <Typography variant="caption">{tid1}</Typography>
                  </DeviceMongoModal>
                  <Typography>Year:{timeStr(tid1Obj?.year)}</Typography>
                  <Typography>Month:{timeStr(tid1Obj?.month)}</Typography>
                  <Typography>Day:{timeStr(tid1Obj?.date)}</Typography>
                  <Typography>Weekday:{timeStr(tid1Obj?.isoWeekday)}</Typography>
                  <Typography>Hour:{timeStr(tid1Obj?.hour)}</Typography>
                  <Typography>Minute:{timeStr(tid1Obj?.minute)}</Typography>
                </div>
              </DivInline>
            );
          })}
        </ModalSM>
      }

    </>
  );
}
