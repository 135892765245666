import { SMContext } from "context/smContext";
import { ArrowForwardIos } from "@mui/icons-material";
import { mgDeleteMany, mgFindByMatch, mgFindOne } from "actions/mongoApiActions";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import { useState, useRef, useContext, useEffect } from "react";
import { Box, InputLabel, Button, TextField, MenuItem, Select, FormControl, Typography, TableRow, TableCell, TableContainer, Table } from "@mui/material";
import IconButtonDelete from "components/IconButtonDelete";
import { alertWindow, confirmWindow } from "actions/screenActions";
// import { isMatch } from "actions/generalActions";
import DivInline from "components/DivInline";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import IconButtonEdit from "components/IconButtonEdit";
import IconButtonAdd from "components/IconButtonAdd";
import moment from "moment";
import General from "@ecoenghk/general";
import JsonModalContainer from "components/JsonModalContainer";
import { addUser, updateUserProperty, deleteUser } from "actions/userActions";
const gs = new General();

const cw = ["30%", "30%", "15%", "5%", "5%", "5%"];
export default function FellowUserManagementModal({ masterUserObj }) {
    const [, dispatch] = useContext(SMContext);
    const { email, uid } = masterUserObj || {};
    const [open, setOpen] = useState(false);
    const [userArray, setUserArray] = useState([]);
    // const [userArrayUse, setUserArrayUse] = useState([]);
    // const [searchText, setSearchText] = useState("");
    const [filterLv, setFilterLv] = useState(99);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [fellowUser, setFellowUser] = useState({});
    const handleOpen = async () => {
        setOpen(true);
        handleFetchUserArray();
    }
    const handleFetchUserArray = async () => {
        let arr = await mgFindByMatch("user", "masterUserUid", uid);
        arr = arr.sort((a, b) => a.email?.localeCompare(b.email));
        setUserArray(arr);
    }
    const handleDeleteUser = async (obj) => {
        confirmWindow(dispatch, `Delete user ${obj.email}?`, async () => {
            await deleteUser(obj);
            await gs.waitFor(500);
            alertWindow(dispatch, `User ${obj.email} deleted`);
            await handleFetchUserArray();
        });
    }
    // useEffect(() => {
    //     let arr = userArray;
    //     if (searchText === "") {
    //         setUserArrayUse(arr);
    //     } else {
    //         let arr = userArray.filter((obj) =>
    //             isMatch(obj?.email || "", searchText)
    //             || isMatch(obj?.uid || "", searchText)
    //             || isMatch(obj?.name || "", searchText)
    //             || isMatch(obj?.password || "", searchText)
    //             || isMatch(String(obj?.level) || "", searchText));
    //         setUserArrayUse(arr);
    //     }
    //     if (filterLv < 99) {
    //         arr = arr.filter((obj) => obj.level === filterLv);
    //         setUserArrayUse(arr);
    //     }
    // }
    //     , [searchText, userArray, filterLv]);
    const handleAddFellowUser = async (entry) => {
        const fellowUid = `${uid.substring(0, 10)}_${gs.getRandomHexID(4)}`;
        const fellowUserObj = {
            email: entry.email,
            uid: fellowUid,
            name: entry.name,
            level: entry.level,
            password: entry.password,
            masterUserUid: uid,
        }
        await addUser(fellowUserObj);
        await gs.waitFor(500);
        await handleFetchUserArray();
    }
    const handleEditFellowUser = async (fellowUserObj, entry) => {
        updateUserProperty(fellowUserObj, entry)
        alertWindow(dispatch, `Fellow user ${fellowUserObj.email} updated`);
        await gs.waitFor(500);
        await handleFetchUserArray();
    }
    return (
        <>
            <Button onClick={handleOpen} variant="outlined"><ArrowForwardIos /></Button>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle="Fellow User Management"
                width="85vw"
                height="80vh"
            >
                <SpaceBetweenDiv title="Master user" data={`${email} [${uid}]`} />
                <Divider10 />
                <DivInline>
                    <Typography>Fellow users</Typography>
                    <AddFellowUserModal handleSave={handleAddFellowUser} />
                </DivInline>
                <Divider10 />
                <TableContainer>
                    <Table>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {
                            userArray.map((obj, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell>{obj.uid}</TableCell>
                                        <TableCell>{obj.email}</TableCell>
                                        <TableCell>{obj.name}</TableCell>
                                        <TableCell>{obj.level}</TableCell>

                                        <TableCell>
                                            <IconButtonEdit onBtnClick={() => {
                                                setOpenEditModal(true);
                                                setFellowUser(obj)
                                            }}
                                                tooltip="Edit fellow user"
                                            />
                                            <IconButtonDelete size="small" tooltip="Delete" onBtnClick={() => handleDeleteUser(obj)} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </Table>
                </TableContainer>

            </ModalSM >
            <EditFellowUserModal
                open={openEditModal}
                handleClose={() => {
                    setOpenEditModal(false);
                    setFellowUser({});
                }}
                masterUserObj={masterUserObj}
                fellowUserObj={fellowUser}
                handleSave={handleEditFellowUser}
            />
        </>
    )
}
function EditFellowUserModal({ open, handleClose, fellowUserObj, handleSave, masterUserObj }) {
    const [entry, setEntry] = useState({});
    useEffect(() => {
        setEntry(fellowUserObj);
    }, [fellowUserObj]);
    return (
        <ModalSM
            open={open}
            onClose={handleClose}
            modalTitle={`Edit fellow user ${entry.email}`}
            onSave={() => {
                handleSave(fellowUserObj, entry);
                handleClose();
            }}
            width="70vw"
            height="70vh"
        >
            <Box sx={{ padding: "1vw" }}>
                <JsonModalContainer jsonData={entry} disabled={masterUserObj.level !== 0} >
                    <SpaceBetweenDiv title="ID" data={entry.uid} />
                </JsonModalContainer>
                <SpaceBetweenDiv title="Email" data={entry.email} />
                <Divider10 />
                <TextField
                    fullWidth
                    label="Password"
                    variant="outlined"
                    value={entry.password || ""}
                    onChange={(e) => setEntry({ ...entry, password: e.target.value })}
                    sx={{ marginBottom: "1vw" }} />
                <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    value={entry.name || ""}
                    onChange={(e) => setEntry({ ...entry, name: e.target.value })}
                    sx={{ marginBottom: "1vw" }} />
                <FormControl fullWidth sx={{ marginBottom: "1vw" }}>
                    <InputLabel id="level-label">Level</InputLabel>
                    <Select variant="outlined" value={entry.level || ""} onChange={(e) => setEntry({ ...entry, level: Number(e.target.value) })}  >
                        <MenuItem value={2}>{"Level 2 - allow operate lights and sensors and not allow to change settings"}</MenuItem>
                        <MenuItem value={3}>{"Level 3 - view only"}</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </ModalSM>
    )
}
function AddFellowUserModal({ handleSave }) {
    const [open, setOpen] = useState(false);
    const [entry, setEntry] = useState({});
    const handleClose = () => {
        setEntry({});
        setOpen(false);
    }
    return (
        <>
            <IconButtonAdd onBtnClick={() => setOpen(true)} tooltip="Add fellow user" />
            <ModalSM
                open={open}
                onClose={handleClose}
                modalTitle="Add fellow user"
                onSave={() => {
                    handleSave(entry);
                    handleClose();
                }}
                width="70vw"
                height="70vh"
            >
                <Box sx={{ padding: "1vw" }}>
                    <TextField
                        fullWidth
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={entry.email || ""}
                        onChange={(e) => setEntry({ ...entry, email: e.target.value })}
                        sx={{ marginBottom: "1vw" }}
                    />
                    <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        value={entry.password || ""}
                        onChange={(e) => setEntry({ ...entry, password: e.target.value })}
                        sx={{ marginBottom: "1vw" }} />
                    <TextField
                        fullWidth
                        label="Name"
                        variant="outlined"
                        value={entry.name || ""}
                        onChange={(e) => setEntry({ ...entry, name: e.target.value })}
                        sx={{ marginBottom: "1vw" }} />
                    <FormControl fullWidth sx={{ marginBottom: "1vw" }}>
                        <InputLabel id="level-label">Level</InputLabel>
                        <Select variant="outlined" value={entry.level || ""} onChange={(e) => setEntry({ ...entry, level: Number(e.target.value) })}  >
                            <MenuItem value={2}>{"Level 2 - allow operate lights and sensors and not allow to change settings"}</MenuItem>
                            <MenuItem value={3}>{"Level 3 - view only"}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </ModalSM>
        </>
    )
}