const headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=UTF-8",
};

////////GET//////////////GET///////////////GET///////////////GET///////////

export const mgFindOne = async (col, key, val) => {
  const url = `${global.ip}/api/mgFindOne?col=${col}&key=${key}&val=${val}&valType=str`;
  try {
    const res = await fetch(url);
    const response = await res.json();
    const data = response.data;
    console.log("mgFindOne", data);
    if (data) {
      return data;
    } else {
      return { result: "fail" };
    }
  } catch (e) {
    return { result: "fail" };
  }
};

export const mgFindAll = async (col) => {
  try {
    const res = await fetch(`${global.ip}/api/mgFindAll?col=${col}`);
    const response = await res.json();
    const data = response.data;
    console.log(data);
    return data;
  } catch (e) {
    return { result: "fail" };
  }
};
export const mgFindManyInArray = async (col, matchField, array) => {
  try {
    const res = await fetch(`${global.ip}/api/mgFindManyInArray`, {
      method: "POST",
      body: JSON.stringify({ collection: col, matchField, array }),
      headers,
    });
    const response = await res.json();
    const data = response.data;
    console.log(data);
    return data;
  } catch (e) {
    return { result: "fail" };
  }
};
export const mgFindInArray = async (col, inArrayField, inArrayVal) => {
  const query = { [inArrayField]: { $in: [inArrayVal] } };
  const res = await mgFindQuery(col, query);
  return res;
};
export const mgFindQuery = async (col, query) => {
  const url = `${global.ip}/api/mgFindQuery`;
  const body = {
    col,
    query,
  };
  try {
    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    const response = await res.json();
    const data = response.data;
    console.log(data);
    if (data) {
      return data;
    } else {
      return { result: "fail" };
    }
  } catch (e) {
    return { result: "fail" };
  }
};
export const mgFindByMatch = async (col, matchField, matchVal) => {
  const query = {
    [matchField]: matchVal,
  };
  const res = await mgFindQuery(col, query);
  return res;
};
export const mgFindByMatchAndRange = async (
  col,
  matchField,
  matchVal,
  rangeField,
  upperRange,
  lowerRange
) => {
  let query = {};
  if (matchField) {
    query = { ...query, [matchField]: matchVal };
  }
  if (rangeField) {
    query = { ...query, [rangeField]: { $gte: lowerRange, $lte: upperRange } };
  }
  const res = await mgFindQuery(col, query);
  return res;
};
export const mgFindByArray = async (col, field, searchArray) => {
  const query = { [field]: { $in: searchArray } };
  const res = await mgFindQuery(col, query);
  return res;
};

/////////SET//////////////SET//////////////SET//////////////SET/////////
export const mgUpdateOneUpsert = async (col, query, updateObj) => {
  const url = `${global.ip}/api/mgUpdateOneUpsert`;
  if ("_id" in updateObj) {
    delete updateObj._id;
  }
  const body = {
    col,
    query,
    data: updateObj,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log("updateOneUpsert", res);
      return res;
    }
  } catch (e) {
    return { result: "fail" };
  }
};

export const mgUpdateNestedJsonKey = async (
  col,
  query,
  jsonName,
  subkey,
  val
) => {
  const url = `${global.ip}/api/mgUpdateNestedJsonKey`;
  const body = {
    col,
    query,
    jsonName,
    subkey,
    val,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log("updateNestedJsonKey", res);
      return res;
    }
  } catch (e) {
    return { result: "fail" };
  }
};

export const mgDeleteNestedJsonKey = async (col, query, jsonName, subkey) => {
  const res = await mgPost("mgDeleteNestedJsonKey", {
    col,
    query,
    jsonName,
    subkey,
  });
  return res;
};
export const mgDeleteDocField = async (col, query, data) => {
  const res = await mgPost("mgDeleteDocField", {
    col,
    query,
    data,
  });
  return res;
};
export const mgDeleteMany = async (col, query) => {
  const url = `${global.ip}/api/mgDeleteMany`;
  const body = {
    col,
    query,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log("mgDeleteMany", res);
      return res;
    }
  } catch (e) {
    return { result: "fail" };
  }
};

export const mgBulkUpdate = async (col, queryKey, updateObjArr, targetIP) => {
  const url = `${targetIP || global.ip}/api/mgBulkUpdate`;
  const body = {
    col,
    queryKey,
    data: updateObjArr,
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log("mgBulkUpdate", res);
      return res;
    }
  } catch (e) {
    return { result: "fail" };
  }
};
export const mgArrayUnion = async (col, query, fieldName, unionDataArr) => {
  const body = {
    col,
    query,
    fieldName,
    data: unionDataArr,
  };
  await mgPost("mgArrayUnion", body);
};

export const mgArrayRemove = async (col, query, fieldName, removeDataArr) => {
  const body = {
    col,
    query,
    fieldName,
    data: removeDataArr,
  };
  await mgPost("mgArrayRemove", body);
};
export const mgInsertMany = async (col, jsonArray) => {
  const url = `${global.ip}/api/mgInsertMany`;
  const body = {
    col,
    jsonArray
  }
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers
    })
    if (response.status === 200) {
      const res = await response.json();
      console.log("mgInsertMany", res)
      return res;
    }
  }
  catch (e) {
    return { result: "fail" }
  }
}
/**
 *
 * @param {string} fsCol e.g. SM_rssi
 * @param {string} queryKey e.g.gatewayID
 * @param {*} queryValue e.g. GWabe026
 * @param {*} fsDocKey e.g. docID
 */
export const deleteFsDocs_equal = async (
  fsCol,
  queryKey,
  queryValue,
  fsDocKey
) => {
  const body = {
    fsCol,
    queryKey,
    queryValue,
    fsDocKey,
  };
  await mgPost("deleteFsDocs_equal", body);
};

/**
 *
 * @param {string} fsCol e.g. SM_serial
 * @param {string} queryKey e.g.mapID
 * @param {*} queryValue e.g. map_aaaaaaa
 * @param {*} fsDocKey e.g. serial
 */
export const deleteFsDocs_arrayContains = async (
  fsCol,
  queryKey,
  queryValue,
  fsDocKey
) => {
  const body = {
    fsCol,
    queryKey,
    queryValue,
    fsDocKey,
  };
  await mgPost("deleteFsDocs_arrayContains", body);
};

export const mgPost = async (apiName, body, targetIP) => {
  const url = `${targetIP || global.ip}/api/${apiName}`;
  console.log("mgPost", apiName, url);
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers,
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log(apiName, res);
      return res;
    }
  } catch (e) {
    return { result: "fail" };
  }
};

