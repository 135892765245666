import moment from "moment";
import {
  mgUpdateOneUpsert,
  mgFindByArray,
  mgBulkUpdate,
  mgDeleteMany,
  mgPost,
} from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

// function lastWeekDayOfMonth(monthMoment, weekDayNo) {
//   //weekDayNo: 0-Mon, 1-Tue,...5-Sat,6-Sun
//   var month = monthMoment.month();
//   monthMoment.endOf("month").startOf("isoweek").add(weekDayNo, "days");
//   if (monthMoment.month() !== month) monthMoment.subtract(7, "days");
//   return monthMoment;
// }

export const fetchScheduleByLightArray = async (lightArr) => {
  const arr = await mgFindByArray("timeTable", "serial", lightArr || []);
  let schObjAll = {};
  lightArr.forEach((s) => {
    schObjAll[s] = [];
  });
  arr.forEach((obj) => {
    const s = obj.serial;
    schObjAll[s] = [...schObjAll[s], obj];
  });
  return schObjAll;
};

// export async function setSchedules(
//   serial,
//   batteryHour,
//   selectedDayType,
//   targetDate,
//   weekDayNo,
//   targetHour,
//   targetMinute,
//   minV,
//   minI,
//   startTestNo,
//   endTestNo,
//   ppaVer,
//   warningV,
//   warningI
// ) {
//   const nowMoment = moment();
//   let firstMoment;
//   if (selectedDayType === "day") {
//     firstMoment = moment()
//       .date(targetDate)
//       .hour(targetHour)
//       .minute(targetMinute);
//   } else if (selectedDayType === "lastWeekDay") {
//     firstMoment = lastWeekDayOfMonth(moment(), weekDayNo);
//   }

//   const shiftMonth = firstMoment.isAfter(nowMoment) ? 0 : 1;

//   let scheduleObjArray = [];
//   for (let i = startTestNo; i < endTestNo; i++) {
//     let timeMoment;
//     if (selectedDayType === "day") {
//       timeMoment = moment()
//         .date(targetDate)
//         .hour(targetHour)
//         .minute(targetMinute)
//         .second(11)
//         //   .startOf("minute")
//         .add(i + shiftMonth, "months");
//     } else if (selectedDayType === "lastWeekDay") {
//       timeMoment = lastWeekDayOfMonth(
//         moment().add(i + shiftMonth, "months"),
//         weekDayNo
//       );
//       timeMoment = timeMoment.hour(targetHour).minute(targetMinute).second(11);
//     }
//     const schedule_timeString = timeMoment.format(`YYYY-MM-DD, HH:mm:ss`);
//     const schedule_timeStamp = timeMoment.valueOf();
//     const scheduleYear = timeMoment.year();
//     const scheduleMonth = timeMoment.month() + 1;
//     let reportType, time_interval, time_pretest, time_cutOff, time_recharge;
//     if (ppaVer === "ed5") {
//       if (i === 11 || i === 23 || i === 35) {
//         reportType = "check_12month";
//         time_interval = 113; //seconds
//         time_pretest = 10; //min
//         time_cutOff = 60 * batteryHour; //min
//         time_recharge = 10; //min
//       } else {
//         reportType = "check_1month";
//         time_interval = 14; //seconds
//         time_pretest = 1; //min
//         time_cutOff = 4; //min
//         time_recharge = 2; //min
//       }
//     } else if (ppaVer === "ed4") {
//       if (i === 5 || i === 11 || i === 17 || i === 23 || i === 29) {
//         reportType = "check_6month";
//         time_interval = 113; //seconds
//         time_pretest = 10; //min
//         time_cutOff = 15 * batteryHour; //min
//         time_recharge = 10; //min
//       } else if (i === 35) {
//         reportType = "check_36month";
//         time_interval = 173; //seconds
//         time_pretest = 20; //min
//         time_cutOff = 60 * batteryHour; //min
//         time_recharge = 20; //min
//       } else {
//         reportType = "check_1month";
//         time_interval = 14; //seconds
//         time_pretest = 1; //min
//         time_cutOff = 4; //min
//         time_recharge = 2; //min
//       }
//     }

//     const no = i < 10 ? "0" + i : i.toString();
//     const scheduleID = `${serial}_${no}_0`;
//     let randomID = "";
//     for (let i = 0; i < 4; i++) {
//       const b = Math.floor(Math.random() * 254);
//       const h = b < 16 ? "0" + b.toString(16) : b.toString(16);
//       randomID += h;
//     }
//     const reportID = serial + "_" + randomID;
//     const obj = {
//       schedule_timeString,
//       scheduleID,
//       schedule_timeStamp,
//       reportType,
//       reportID,
//       serial,
//       no,
//       time_interval,
//       time_pretest,
//       time_cutOff,
//       time_recharge,
//       rescheduleCount: 0,
//       minV,
//       minI,
//       warningV,
//       warningI,
//       scheduleYear,
//       scheduleMonth,
//     };
//     scheduleObjArray = [...scheduleObjArray, obj];
//   }
//   // await insertManyDocsDB("schedule", scheduleObjArray);
// }

// export async function setSchedulesByMultipleTimeLS(
//   scheduleTimeArrayObj,
//   lightObjAll,
//   ppaVer
// ) {
//   console.log({ scheduleTimeArrayObj });
//   let scheduleObjArray = [];
//   Object.keys(scheduleTimeArrayObj || {}).forEach((serial) => {
//     const { batteryHour, minVRequired, minIRequired, warningV, warningI } =
//       lightObjAll[serial] || {};
//     const scheduleTimeArray = scheduleTimeArrayObj[serial];
//     scheduleTimeArray.forEach((tsr, k) => {
//       const no = k < 10 ? "0" + k : k.toString();
//       const reportTypeTimeObj = getReportTypeAndTestTime(
//         ppaVer,
//         batteryHour,
//         k
//       );
//       const timeStr = tsr.trim().replace(",", "T");
//       const tmoment = moment(timeStr).add(11, "seconds");
//       const timeStamp = tmoment.valueOf();
//       const timeString = tmoment.format("YYYY-MM-DD, HH:mm:ss");
//       const scheduleYear = tmoment.year();
//       const scheduleMonth = tmoment.month() + 1;
//       const obj = {
//         serial,
//         no,
//         scheduleID: `${serial}_${no}_0`,
//         schedule_timeString: timeString,
//         schedule_timeStamp: timeStamp,
//         reportType: reportTypeTimeObj.reportType,
//         reportID: getRandomReportID(serial),
//         time_interval: reportTypeTimeObj.time_interval,
//         time_pretest: reportTypeTimeObj.time_pretest,
//         time_cutOff: reportTypeTimeObj.time_cutOff,
//         time_recharge: reportTypeTimeObj.time_recharge,
//         rescheduleCount: 0,
//         minV: Number(minVRequired),
//         minI: Number(minIRequired),
//         warningV: Number(warningV),
//         warningI: Number(warningI),
//         scheduleYear,
//         scheduleMonth,
//       };
//       scheduleObjArray = [...scheduleObjArray, obj];
//     });
//   });
//   console.log({ scheduleObjArray });
//   // await insertManyDocsDB("schedule", scheduleObjArray);
// }

// export function getRandomReportID(serial) {
//   let randomID = "";
//   for (let i = 0; i < 4; i++) {
//     const b = Math.floor(Math.random() * 254);
//     const h = b < 16 ? "0" + b.toString(16) : b.toString(16);
//     randomID += h;
//   }
//   const reportID = serial + "_" + randomID;
//   return reportID;
// }

export function getReportTypeAndTestTime(ppaVer, batteryHour, monthNo) {
  let reportType, time_interval, time_pretest, time_cutOff, time_recharge;
  if (ppaVer === "ed5") {
    if (monthNo === 11 || monthNo === 23 || monthNo === 35) {
      reportType = "check_12month";
      time_interval = 113; //seconds
      time_pretest = 10; //min
      time_cutOff = 60 * Number(batteryHour); //min  full duration
      time_recharge = 10; //min
    } else {
      reportType = "check_1month";
      time_interval = 14; //seconds
      time_pretest = 2; //min
      time_cutOff = 4; //min
      time_recharge = 2; //min
    }
  } else if (ppaVer === "ed4") {
    if (
      monthNo === 5 ||
      monthNo === 11 ||
      monthNo === 17 ||
      monthNo === 23 ||
      monthNo === 29
    ) {
      reportType = "check_6month";
      time_interval = 113; //seconds
      time_pretest = 10; //min
      time_cutOff = (60 * Number(batteryHour)) / 4; //min  1/4 rated duration
      time_recharge = 10; //min
    } else if (monthNo === 35) {
      reportType = "check_36month";
      time_interval = 173; //seconds
      time_pretest = 20; //min
      time_cutOff = 60 * Number(batteryHour); //min  full duration
      time_recharge = 20; //min
    } else {
      reportType = "check_1month";
      time_interval = 14; //seconds
      time_pretest = 2; //min
      time_cutOff = 4; //min
      time_recharge = 2; //min
    }
  }
  return {
    reportType,
    time_interval,
    time_pretest,
    time_cutOff,
    time_recharge,
  };
}

// export const getSchBoxColorText = (reportType) => {
//   let backgroundColor, tooltipText;
//   switch (reportType) {
//     case "check_6month": {
//       backgroundColor = "#ffb3d9";
//       tooltipText = "6-month test";
//       break;
//     }
//     case "check_12month": {
//       backgroundColor = "#ff6600";
//       tooltipText = "Annual test";
//       break;
//     }
//     case "check_36month": {
//       backgroundColor = "#ff3300";
//       tooltipText = "3-year test";
//       break;
//     }
//     default: {
//       backgroundColor = "#669999";
//       tooltipText = "Monthly test";
//     }
//   }
//   return { backgroundColor, tooltipText };
// };

// export const editSingleSchedule = async (serverObj, scheduleID, testMoment) => {
//   let newSchObj = {
//     schedule_timeString: testMoment.format("YYYY-MM-DD, HH:mm:ss"),
//     schedule_timeStamp: testMoment.valueOf(),
//   };
//   console.log(newSchObj);
//   await mgUpdateOneUpsert(
//     "schedule_oneTime",
//     { scheduleID: scheduleID },
//     newSchObj
//   );
//   if (serverObj.enableUploadFS) {
//     await gs.waitFor(800);
//     const body = {
//       col: "schedule_oneTime",
//       query: { scheduleID: scheduleID },
//       fsCol: "SM_schedule",
//       fsDocKey: "scheduleID",
//     };
//     await mgPost("mgUploadFs", body);
//   }
// };
// export const deleteSingleSchedule = async (serverObj, scheduleID) => {
//   const query = { scheduleID: scheduleID };
//   await mgDeleteMany("schedule_oneTime", query);
//   if (serverObj.enableUploadFS) {
//     console.log(`FS delete ${scheduleID}`);
//     const body = { fsDocPath: `SM_schedule/${scheduleID}` };
//     await mgPost("deleteFsSingleDoc", body);
//   }
// };

// export const editAllSchedules = async (
//   serverObj,
//   serial,
//   schObjArr,
//   testDay,
//   lastDay,
//   testHour,
//   testMin
// ) => {
//   let updateArr = [];
//   await gs.asyncForEach(schObjArr, async (schObj) => {
//     const { scheduleID, schedule_timeStamp } = schObj;
//     let newMoment;
//     if (lastDay === 0) {
//       newMoment = moment(schedule_timeStamp)
//         .date(testDay)
//         .hour(testHour)
//         .minute(testMin);
//     } else {
//       newMoment = moment(schedule_timeStamp)
//         .endOf("month")
//         .subtract(lastDay - 1, "days")
//         .hour(testHour)
//         .minute(testMin);
//     }
//     // console.log(scheduleID, newMoment.format("YYYY-MM-DD,HH:mm"));
//     // await editSingleSchedule(scheduleID, newMoment);
//     const obj = {
//       scheduleID: scheduleID,
//       schedule_timeString: newMoment.format("YYYY-MM-DD, HH:mm:ss"),
//       schedule_timeStamp: newMoment.valueOf(),
//     };
//     updateArr = [...updateArr, obj];
//   });
//   console.log(updateArr);
//   await mgBulkUpdate("schedule_oneTime", "scheduleID", updateArr);
//   if (serverObj.enableUploadFS) {
//     await gs.waitFor(1500);
//     const body = {
//       col: "schedule_oneTime",
//       query: { serial: serial },
//       fsCol: "SM_schedule",
//       fsDocKey: "scheduleID",
//     };
//     await mgPost("mgUploadFs", body);
//   }
// };
// export const deleteAllSchedules = async (serverObj, serial) => {
//   const query = { serial: serial };
//   await mgDeleteMany("schedule_oneTime", query);
//   if (serverObj.enableUploadFS) {
//     const body = {
//       fsCol: "SM_schedule",
//       queryKey: "serial",
//       queryValue: serial,
//       fsDocKey: "scheduleID",
//     };
//     await mgPost("deleteFsDocs_equal", body);
//   }
// };
// export const addYearSchedules = async (
//   serverObj,
//   serial,
//   testDay,
//   lastDay,
//   testHour,
//   testMin,
//   startMonth,
//   batteryHour
// ) => {
//   let schObjArr = [];
//   [...Array(12).keys()].forEach((testNo) => {
//     let actualTestDay = 0;
//     if (lastDay === 0) {
//       actualTestDay = testDay;
//     } else {
//       const m = moment(startMonth + "-1")
//         .add(testNo, "months")
//         .endOf("month")
//         .subtract(lastDay, "days");
//       actualTestDay = m.date();
//     }
//     const testM = moment(startMonth + "-" + actualTestDay)
//       .add(testNo, "months")
//       .hour(testHour)
//       .minute(testMin);
//     // const testDate = testM.format("YYYY-MM-DD");
//     const scheduleYear = Number(testM.format("YYYY"));
//     const scheduleMonth = Number(testM.format("MM"));
//     const {
//       reportType,
//       time_interval,
//       time_pretest,
//       time_cutOff,
//       time_recharge,
//     } = getReportTypeAndTestTime("ed5", batteryHour, Number(testNo));
//     const randomID = gs.getRandomHexID(4);
//     const insertObj = {
//       serverID: serverObj.serverID,
//       scheduleTarget: "server",
//       timeActionType: "scheduledTest_single",
//       reportType,
//       no: testNo < 10 ? "0" + testNo : String(testNo),
//       serial,
//       reportID: serial + "_" + randomID,
//       time_pretest,
//       time_cutOff,
//       time_recharge,
//       time_interval,
//       rescheduleCount: 0,
//       scheduleID: `${serial}_${testNo}_${randomID}`,
//       schedule_timeStamp: testM.valueOf(),
//       schedule_timeString: testM.format("YYYY-MM-DD, HH:mm:ss"),
//       scheduleYear,
//       scheduleMonth,
//       scheduleCreatedTimeStamp: testM.valueOf(),
//       scheduleCreatedTimeString: testM.format("YYYY-MM-DD, HH:mm:ss"),
//       scheduleSource: "server",
//     };
//     schObjArr = [...schObjArr, insertObj];
//   });
//   await mgBulkUpdate("schedule_oneTime", "scheduleID", schObjArr);
//   if (serverObj.enableUploadFS) {
//     await gs.waitFor(800);
//     const body = {
//       col: "schedule_oneTime",
//       query: { serial: serial },
//       fsCol: "SM_schedule",
//       fsDocKey: "scheduleID",
//     };
//     await mgPost("mgUploadFs", body);
//   }
// };
// export const addSingleSchedule = async (
//   serverObj,
//   serial,
//   lightObjAll,
//   testNo,
//   testDate,
//   testHour,
//   testMin,
//   rescheduleCount,
//   scheduleYear,
//   scheduleMonth
// ) => {
//   const { batteryHour } = lightObjAll[serial] || {};
//   const {
//     reportType,
//     time_interval,
//     time_pretest,
//     time_cutOff,
//     time_recharge,
//   } = getReportTypeAndTestTime("ed5", batteryHour, Number(testNo));
//   const randomID = gs.getRandomHexID(4);
//   const testM = moment(testDate).hour(testHour).minute(testMin);
//   const scheduleID = `${serial}_${testNo}_${randomID}`;
//   const insertObj = {
//     serverID: serverObj.serverID,
//     scheduleTarget: "server",
//     timeActionType: "scheduledTest_single",
//     reportType,
//     no: testNo < 10 ? "0" + testNo : String(testNo),
//     serial,
//     reportID: serial + "_" + randomID,
//     time_pretest,
//     time_cutOff,
//     time_recharge,
//     time_interval,
//     rescheduleCount,
//     scheduleID,
//     schedule_timeStamp: testM.valueOf(),
//     schedule_timeString: testM.format("YYYY-MM-DD, HH:mm:ss"),
//     scheduleYear,
//     scheduleMonth,
//     scheduleCreatedTimeStamp: testM.valueOf(),
//     scheduleCreatedTimeString: testM.format("YYYY-MM-DD, HH:mm:ss"),
//     scheduleSource: "server",
//   };
//   console.log(insertObj);
//   await mgUpdateOneUpsert(
//     "schedule_oneTime",
//     { scheduleID: scheduleID },
//     insertObj
//   );
//   if (serverObj.enableUploadFS) {
//     await gs.waitFor(800);
//     const body = {
//       col: "schedule_oneTime",
//       query: { scheduleID: scheduleID },
//       fsCol: "SM_schedule",
//       fsDocKey: "scheduleID",
//     };
//     await mgPost("mgUploadFs", body);
//   }
// };

// export const copyMultiSchedules = async (
//   serverObj,
//   targetSerial,
//   schObjArr
// ) => {
//   let arr = [];
//   schObjArr.forEach((obj) => {
//     const randomID = gs.getRandomHexID(4);
//     const newScheduleID = `${targetSerial}_${obj.no}_${randomID}`;
//     const newObj = {
//       ...obj,
//       scheduleID: newScheduleID,
//       serial: targetSerial,
//       reportID: targetSerial + "_" + randomID,
//       rescheduleCount: 0,
//     };
//     delete newObj._id;
//     arr = [...arr, newObj];
//   });
//   // console.log("target:",arr);
//   await mgBulkUpdate("schedule_oneTime", "scheduleID", arr);
//   if (serverObj.enableUploadFS) {
//     await gs.waitFor(1500);
//     const body = {
//       col: "schedule_oneTime",
//       query: { serial: targetSerial },
//       fsCol: "SM_schedule",
//       fsDocKey: "scheduleID",
//     };
//     await mgPost("mgUploadFs", body);
//   }
// };
