import { useContext } from "react";
import { SMContext } from "context/smContext";
import { ButtonGroup, Typography, Button } from "@mui/material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivInline from "components/DivInline";
import {
  confirmWindow,
  showBigProgress,
  hideBigProgress,
  alertWindow,
} from "actions/screenActions";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DialogInput from "components/DialogInput";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import {
  checkSensorConnection,
  updateSensorProperty,
  changeSensorOneSetting,
  queryOneSensorSetting,
  sensorTestSettingReadWrite,
  flashTouchPanelLed
} from "actions/sensorActions";
import { sendStartSettingToDevice, serverUpdateToGateway } from "actions/gatewayActions";
import General from "@ecoenghk/general";
import { ArrowForwardIos } from "@mui/icons-material";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import ServerConsoleLogFilter from "containers/server/ServerConsoleLogFilter";
import DeviceInterfaceTtyPanel from "containers/gateway/DeviceInterfaceTtyPanel";
import { sensorHeadTypeNameOf } from "actions/generalActions";
const gs = new General();

export default function SensorSuperAdminPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { activeSensorID, sensorObjAll, socket, daliCtlObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const {
    gatewayID,
    regular_time_interval,
    survival_time_interval,
    serverID,
    hasIR,
    daliCtlID,
    type
  } = sensorObj || {};
  const handleUpdateProperty = async (updateObj) => {
    showBigProgress(dispatch);
    const did = sensorObj.daliCtlID;
    const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    await updateSensorProperty(activeSensorID, sensorObj, gid, updateObj);
    hideBigProgress(dispatch);
  };
  // const interfaceType = dtkChannel ? "DTK" : meshChannel >= 0 ? "Mesh255" : ""
  // const handleConvertDimController = async (en) => {
  //   if (Object.keys(sensorObj?.serialMap || {}).length > 0) {
  //     alertWindow(dispatch, "Please disconnect all lights before converting");
  //   } else if (Object.keys(sensorObj?.sensorMap || {}).length > 0) {
  //     alertWindow(dispatch, "Please disconnect all sensors before converting");
  //   } else {
  //     let msg = `Confirm to convert to ${en ? "dim controller" : "scene button"}?`;
  //     confirmWindow(dispatch, msg, async () => {
  //       await showBigProgress(dispatch);
  //       await updateSensorProperty(activeSensorID, sensorObj, gatewayID, { enableDimController: en })
  //       await hideBigProgress(dispatch);
  //       // handleClose();
  //       // dispatch({
  //       //   type: "ALL_UNSELECTED",
  //       // });
  //     });
  //   }
  // }
  return (
    <>
      <Typography variant="h6" align="center">
        Super admin
      </Typography>
      <Divider10 />
      <SpaceBetweenDiv title="Server ID" data={serverID} />


      <DivExist show={type === "motion" || type === "daylightmotion"}>
        <SpaceBetweenSwitch
          title="Has IR sensor"
          data={hasIR ? true : false}
          onChange={async (e) => {
            let val = e.target.checked ? 1 : 0;
            await changeSensorOneSetting(sensorObj, 0x0610, { hasIR: val });
          }}
        />
      </DivExist>

      {/* <SpaceBetweenDiv
        title="Transfer light settings to other sensor"
        data={<TransferSettingToSensorModal />}
      /> */}
      <SpaceBetweenDialogInput
        title="Regular upload time interval"
        data={regular_time_interval}
        dialogTitle="Change regular upload time interval (min)"
        showValueUnit="min"
        handleSave={async (val) => await changeSensorOneSetting(sensorObj, 0x0623, { regular_time_interval: Number(val) })}
        hideComponent={daliCtlID}
        handleRefresh={() => queryOneSensorSetting(sensorObj, 0x071a)}
      />
      <SpaceBetweenDialogInput
        title="Survival time interval"
        data={survival_time_interval}
        dialogTitle="Change survival time interval (second)"
        showValueUnit="sec"
        handleSave={async (val) => await changeSensorOneSetting(sensorObj, 0x0624, { survival_time_interval: Number(val) })}
        hideComponent={daliCtlID}
        handleRefresh={() => queryOneSensorSetting(sensorObj, 0x071b)}
      />
      <SpaceBetweenButton
        title="Request feedback (check connection)"
        btnContent={<ArrowForwardIos />}
        variant="outlined"
        onBtnClick={async () =>
          await checkSensorConnection(dispatch, socket, activeSensorID, sensorObj)
        }
        hideComponent={daliCtlID}
        marginBottom="1vh"
      />
      <SpaceBetweenButton
        title="Send start setting"
        btnContent={<ArrowForwardIos />}
        variant="outlined"
        onBtnClick={async () => await sendStartSettingToDevice("sensor", activeSensorID, gatewayID)}
        hideComponent={daliCtlID}
        marginBottom="1vh"
      />
      <SpaceBetweenButton
        title="Update single sensor to gateway"
        btnContent={<ArrowForwardIos />}
        variant="outlined"
        onBtnClick={async () =>
          serverUpdateToGateway(socket, gatewayID, "singleSensor", activeSensorID)
        }
        marginBottom="1vh"
      />

      <SpaceBetweenDialogInput
        title="PCB version"
        data={sensorObj.pcbVersion}
        dialogTitle="Change PCB version"
        handleSave={async (val) => {
          await handleUpdateProperty({
            pcbVersion: val,
          });
        }}
      />
      <SpaceBetweenButton
        title="Check internal setting read/write"
        onBtnClick={() => sensorTestSettingReadWrite(sensorObj)}
        btnContent={<ArrowForwardIos />}
      />
      <SpaceBetweenDiv
        title="Sensor head type"
        data={sensorHeadTypeNameOf(sensorObj.sensorHeadType)}
        handleRefresh={() => queryOneSensorSetting(sensorObj, 0x0726)}
      />
      <DivExist show={type === "scenebutton" || type === "lightswitch" || type === 'dimcontroller'}>
        <SpaceBetweenButton title="Flash touch panel LEDs" onBtnClick={async () => flashTouchPanelLed(sensorObj)} btnContent={<ArrowForwardIos />} />
        <SpaceBetweenSelect
          title="Touch panel mode"
          data={sensorObj.touchPanelType}
          handleRefresh={() => queryOneSensorSetting(sensorObj, 0x0725)}
          menuObj={{ 1: "momentary", 2: "latch" }}
          onChange={async (e) => {
            const val = Number(e.target.value);
            await changeSensorOneSetting(sensorObj, 0x0633, { touchPanelType: val });
          }}
        />
        <SpaceBetweenDiv
          title="Continuous flash button LED"
          data={
            <ButtonGroup variant="outlined" size='small'>
              <Button onClick={() => queryOneSensorSetting(sensorObj, 0x0636)}>Start</Button>
              <Button onClick={() => queryOneSensorSetting(sensorObj, 0x0637)}>Stop</Button>
            </ButtonGroup>
          }
        />
        <SpaceBetweenButton
          title="Check touch panel gang"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0728)}
        />
        <SpaceBetweenSwitch
          title="Touch panel LED reverse"
          data={sensorObj.reverseLed ? true : false}
          onChange={async (e) => {
            let val = e.target.checked ? 1 : 0;
            await changeSensorOneSetting(sensorObj, 0x063b, { reverseLed: val });
          }}
          handleRefresh={() => queryOneSensorSetting(sensorObj, 0x072b)}
        />
        <SpaceBetweenButton
          title="Check touch panel version"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0727)}
        />
        <SpaceBetweenButton
          title="Update touch panel firmware"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => queryOneSensorSetting(sensorObj, 0x0638)}
        />
      </DivExist>
      <DivExist show={type === "scenebutton"}>
        <SpaceBetweenDialogInput
          title="Minimum press time"
          data={sensorObj.sceneBtnMinTime}
          handleSave={async (val) =>
            await changeSensorOneSetting(sensorObj, 0x0631, { sceneBtnMinTime: Number(val) })
          }
          handleRefresh={async () =>
            queryOneSensorSetting(sensorObj, 0x0723)
          }
          showValue
          showValueUnit="sec"
        />
        {/* <SpaceBetweenDialogInput
          title="Long press repeat interval"
          data={sensorObj.sceneBtnRepeatTime}
          handleSave={async (val) =>
            await changeSensorOneSetting(sensorObj, 0x0632, { sceneBtnRepeatTime: Number(val) })
          }
          handleRefresh={async () =>
            queryOneSensorSetting(sensorObj, 0x0724)
          }
          showValue
          showValueUnit="sec"
        /> */}
      </DivExist>
      <DivExist show={type === "motion"}>
        <SpaceBetweenSwitch
          title="Disable relay output"
          data={sensorObj.disabledRelay ? true : false}
          onChange={async (e) => {
            let val = e.target.checked ? 1 : 0;
            await changeSensorOneSetting(sensorObj, 0x061b, { disabledRelay: val });
          }}
          handleRefresh={() => queryOneSensorSetting(sensorObj, 0x070f)}
        />
        <SpaceBetweenSwitch
          title="Disable contact output"
          data={sensorObj.disabledContact ? true : false}
          onChange={async (e) => {
            let val = e.target.checked ? 1 : 0;
            await changeSensorOneSetting(sensorObj, 0x061c, { disabledContact: val });
          }}
          handleRefresh={() => queryOneSensorSetting(sensorObj, 0x0710)}
        />
      </DivExist>
      <SpaceBetweenSwitch
        title="Enable group action"
        data={sensorObj?.enableGroupAction || false}
        onChange={async (e) => {
          const val = e.target.checked;
          updateSensorProperty(activeSensorID, sensorObj, gatewayID, { enableGroupAction: val })
        }}
      />
      <SpaceBetweenButton
        title="Check number of stored group action"
        btnContent={<ArrowForwardIos />}
        onBtnClick={() => queryOneSensorSetting(sensorObj, 0x072d)}
      />
      <Divider10 />
      <Typography>Communication</Typography>
      <Divider10 />
      <SpaceBetweenDiv
        title="Wifi AP mac address"
        data={sensorObj?.wifiApMacAddress}
        handleRefresh={() => queryOneSensorSetting(sensorObj, 0x0720)}
      />
      <DeviceInterfaceTtyPanel deviceObj={sensorObj} />
      <Divider10 />
      <ServerConsoleLogFilter filterString={activeSensorID} />
      <Divider10 />


    </>
  );
}
