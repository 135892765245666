import { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, IconButton, Button } from "@mui/material";
import { Refresh, Summarize } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import Space10 from "components/Space10";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import CurrentWithPolarity from "components/CurrentWithPolarity";
import ResultIcon from "components/ResultIcon";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import PowerPlug from "asset/svgComp/PowerPlug";
import DetailHistoryModal from "./DetailHistoryModal";
import { LightHistoryItem } from "./LightHistoryItem";
import { mgFindByMatchAndRange } from "actions/mongoApiActions";
import DivInline from "components/DivInline";
import SingleReportModal from "containers/testReport/SingleReportModal";
import LightReportListModal from "containers/testReport/LightReportListModal";
import {
    fetchLightHistory,
    fetchOneLight,
    fetchOneReport,
    queryOneLightSetting,
    requestLightCurrentData,
} from "actions/lightActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import LightIcon from "components/LightIcon";
import numeral from "numeral";
export default function LightStatusPanel({ }) {
    const [state, dispatch] = useContext(SMContext);
    const { activeSerial, lightObjAll, userObj, daliCtlObjAll } = state;
    let lightObj = lightObjAll[activeSerial];
    const { type, currentData, reversePolarityI, timeStamp, reportObj } = lightObj || {};
    const { onoffstatusA, onoffstatusL, lightLv, voltage, lux, bypass } = currentData || {};
    return (
        <>
            <DivInline justifyContent="center">
                <PowerPlug cutResume="resume" width={3} sizeUnit="vw" />
                <Typography variant="h6">Latest data</Typography>
                <IconButtonRefresh size="small" hide={userObj.level >= 2} onBtnClick={async () =>
                    await requestLightCurrentData(activeSerial, lightObj)
                } />
            </DivInline>
            <div style={{ width: "100%" }}>
                <DivExist show={type?.batVI} >
                    <SpaceBetweenDiv title="Battery voltage" data={numeral(voltage).format("0.00") + "V"} dataVariant="body2" />
                    <SpaceBetweenDiv
                        title={`Battery current ${reversePolarityI
                            ? "(+charge/-discharge)"
                            : "(+discharge/-charge)"
                            }`}
                        data={
                            <CurrentWithPolarity
                                darkLight="light"
                                serial={activeSerial}
                                variant="body2"
                            />
                        }
                        dataVariant="body2"
                    />
                </DivExist>
                <DivExist show={type?.L}>
                    <SpaceBetweenDiv
                        title="Power status"
                        data={onoffstatusL ? "cut[1]" : "normal[0]"}
                        dataVariant="body2"
                    />
                </DivExist>
                <DivExist show={type?.A}>
                    <SpaceBetweenDiv
                        title="Light status"
                        data={onoffstatusA ? "off[1]" : "on[0]"}
                        dataVariant="body2"
                    />
                    <SpaceBetweenDiv
                        title="Bypass status"
                        data={bypass ? "Bypass input detected" : "normal"}
                        dataVariant="body2"
                        handleRefresh={() => queryOneLightSetting(lightObj, 0x0324)}
                    />
                </DivExist>
                <DivExist show={type?.pwm}>
                    <SpaceBetweenDiv
                        title="Light level"
                        data={`${lightLv}%`}
                        dataVariant="body2"
                    />
                </DivExist>
                <DivExist show={type?.lux}>
                    <SpaceBetweenDiv
                        title="Measured lux level"
                        data={lux + "lux"}
                        dataVariant="body2"
                    />
                </DivExist>
                <SpaceBetweenDiv
                    title="Latest data time"
                    data={moment(timeStamp).format("YYYY-MM-DD, HH:mm:ss")}
                    dataVariant="body2"
                />
            </div>
        </>
    )
}