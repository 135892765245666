import React from "react";
import { Tooltip } from "@mui/material";
import Downlight from "asset/svgComp/Downlight";
import Tube from "asset/svgComp/Tube";
import Panellight from "asset/svgComp/Panellight";
import Owl from "asset/svgComp/Owl";
import Exit from "asset/svgComp/Exit";
import moment from "moment";
import LightBulb from "asset/svgComp/LightBulb";
import { deviceConnected } from "actions/generalActions";
import Bypass from "asset/svgComp/Bypass";
import AcRemote from "asset/svgComp/AcRemote";

/**
 * @description if use lightObj,also add gatewayConnected. And the following props will be ignored: statusA, statusL, lightLv, typeBatVI, typeIsDim, connectStatus, disabledConnectStatus
 * @param {number} param0.width
 * @param {"vw"|"px"} param0.sizeUnit
 * @param {Object} param0.lightObj
 * @param {boolean} param0.gatewayConnected
 * @param {0|1} param0.statusA
 * @param {0|1} param0.statusL
 * @param {number} param0.lightLv
 * @param {("downlight"|"tube"|"panellight"|"owl"|"exit")} param0.lightStyle
 * @param {boolean} param0.typeBatVI
 * @param {boolean} param0.typeIsDim
 * @param {boolean} param0.connectStatus
 * @param {boolean} param0.disabledConnectStatus
 * @param {boolean} param0.disabledStatus
 * @param {boolean} param0.disabledControlL
 * @param {string} param0.tooltip
 * @param {Object} param0.style
 * @param {string} param0.type
 * @param {0|1} param0.bypass
 *
 *
 * @returns
 */
export default function LightIcon({
  width,
  sizeUnit,
  lightObj,
  gatewayConnected,
  statusA,
  statusL,
  lightLv,
  lightStyle,
  typeBatVI,
  typeIsDim,
  connectStatus,
  disabledConnectStatus,
  disabledStatus,
  disabledControlL,
  tooltip,
  style,
  type,
  backgroundColor,
  bypass,
  daliCtlObj
}) {
  const lightStyleUse = lightObj?.style || lightStyle;
  let statusA_use = lightObj?.currentData?.onoffstatusA ?? statusA ?? 1;
  const statusL_use = lightObj?.currentData?.onoffstatusL ?? statusL ?? 0;
  let lightLvUse = lightObj?.currentData?.lightLv ?? lightLv ?? 0;
  const typeBatVIUse = lightObj?.type?.batVI || typeBatVI;
  const typeIsDimUse = lightObj?.type?.pwm || typeIsDim;
  const timeStampUse = lightObj?.timeStamp;
  const bypass_use = lightObj?.currentData?.bypass ?? bypass ?? 0;
  if (lightObj && daliCtlObj) {
    if (lightObj.daliCtlID) {
      if (!daliCtlObj.powerOnOffStatus) {
        statusA_use = 1;
        lightLvUse = 0;
      }
    }
  }
  let connectStatusUse;
  if (connectStatus) {
    connectStatusUse = true;
  } else {
    if (!timeStampUse) {
      connectStatusUse = false;
    } else {
      connectStatusUse = deviceConnected(
        lightObj?.zigbeeConnected,
        timeStampUse,
        gatewayConnected
      );
    }
  }

  return (
    <div>
      <Tooltip title={tooltip || ""}>
        <div>
          {lightStyleUse === "downlight" ? (
            <Downlight
              width={width}
              sizeUnit={sizeUnit}
              statusA={statusA_use}
              statusL={statusL_use}
              lightLv={lightLvUse}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              typeBatVI={typeBatVIUse}
              typeIsDim={typeIsDimUse}
              bypass={bypass_use}
            />
          ) : lightStyleUse === "tube" ? (
            <Tube
              width={width}
              sizeUnit={sizeUnit}
              statusA={statusA_use}
              statusL={statusL_use}
              lightLv={lightLvUse}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              typeBatVI={typeBatVIUse}
              typeIsDim={typeIsDimUse}
              bypass={bypass_use}
            />
          ) : lightStyleUse === "panellight" ? (
            <Panellight
              width={width}
              sizeUnit={sizeUnit}
              statusA={statusA_use}
              statusL={statusL_use}
              lightLv={lightLvUse}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              typeBatVI={typeBatVIUse}
              typeIsDim={typeIsDimUse}
              bypass={bypass_use}
            />
          ) : lightStyleUse === "owl" ? (
            <Owl
              width={width}
              sizeUnit={sizeUnit}
              statusL={statusL_use}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
            />
          ) : lightStyleUse === "exit" ? (
            <Exit
              width={width}
              sizeUnit={sizeUnit}
              statusL={statusL_use}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
            />
          ) : lightStyleUse === "airconControl" ? (
            <AcRemote
              width={width}
              sizeUnit={sizeUnit}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
            />
          ) : (
            <LightBulb
              width={width}
              sizeUnit={sizeUnit}
              disabledStatus={disabledStatus}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              onoff={statusA_use === 0 ? "on" : "off"}
              lightLv={lightLvUse}
            />
          )}
        </div>
      </Tooltip>
    </div>
  );
}
