import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, CircularProgress } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import IconButtonClose from "components/IconButtonClose";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivSpaceBetween from "components/DivSpaceBetween";
import Divider10 from "components/Divider10";
import { confirmWindow } from "actions/screenActions";
import DivExist from "components/DivExist";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import CopyDeviceToMapModal from "containers/map/modal/CopyDeviceToMapModal";
import {
    updateDaliCtlProperty,
    daliScanShortAdd,
    daliInitialise,
    cancelDaliScan,
    daliQueryLightLevel,
    changeDaliCtlOneSetting,
    queryDaliCtlOneSetting,
    daliQueryLightLockLevel,
} from "actions/daliCtlActions";
import DivCol from "components/DivCol";
import ModalRemarks from "components/ModalRemarks";
import { restartDaliCtl } from "actions/daliCtlActions";
import DeviceUpdateFirmwareModal from "containers/server/DeviceUpdateFirmwareModal";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";

export default function DaliCtlInfoPanel() {
    const [state, dispatch] = useContext(SMContext);
    const {
        daliCtlObjAll,
        activeDaliCtlID,
        gatewayObjAll,
        openDaliCtlDetailModal,
        userObj,
        mapObjAll,
    } = state;
    const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
    const {
        daliMasterModel,
        daliType,
        description,
        gatewayID,
        zigbeeConnected,
        lightObj,
        sensorObj,
        scanning,
        daliMasterID,
        dtkAdd,
        mapID
    } = daliCtlObj || {};
    return (
        <>
            <Typography variant="h6" align="center">General</Typography>
            <Divider10 />
            <SpaceBetweenDiv
                title="Dali controller ID"
                data={activeDaliCtlID}
            />
            <SpaceBetweenDialogInput
                title="Dali controller name"
                dialogTitle="Update dali controller name"
                data={description || ""}
                handleSave={async (val) =>
                    await updateDaliCtlProperty(activeDaliCtlID, {
                        description: val,
                    })
                }
            />
            <DivExist show={userObj.level > 0}>
                <SpaceBetweenDiv title="Model" data={daliMasterModel} />
                <SpaceBetweenDiv title="Dali master ID" data={daliMasterID} />
            </DivExist>
            <DivExist show={daliMasterModel?.includes("Skymon")}>
                <SpaceBetweenDiv
                    title="Wireless address"
                    data={dtkAdd}
                />
            </DivExist>
            <SpaceBetweenDiv
                title="Gateway"
                data={
                    <DivCol alignItems="flex-end">
                        <Typography>
                            {gatewayObjAll[gatewayID]?.description}
                        </Typography>
                        <Typography variant="caption">[{gatewayID}]</Typography>
                    </DivCol>
                }
            />
            <SpaceBetweenDiv title="In Map"
                data={
                    <div>
                        {mapID
                            ?.filter((id) => Object.keys(mapObjAll).includes(id))
                            ?.map((id, key) => (
                                <Typography
                                    align="right"
                                    display="block"
                                    variant="caption"
                                    key={key}
                                >
                                    {mapObjAll[id]?.mapName} [{id}]
                                </Typography>
                            ))}
                    </div>
                }
            />
            <DivExist show={userObj.level < 2}>
                <SpaceBetweenButton
                    marginTop="0.5vh"
                    title="Dali initialise"
                    btnContent={<ArrowForwardIos />}
                    onBtnClick={async () => {
                        confirmWindow(dispatch, "Confirm to initialise?", () =>
                            daliInitialise(daliCtlObj)
                        );
                    }}
                    hideComponent={!daliMasterModel?.includes("Skymon")}
                />
                <DivSpaceBetween style={{ marginTop: "0.5vh" }}>
                    <Typography>Scan connect lights</Typography>
                    {!scanning ? (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                confirmWindow(
                                    dispatch,
                                    "Scan may take up to 10 minutes, continue?",
                                    async () => {
                                        await daliScanShortAdd(daliCtlObj, dispatch);
                                    }
                                );
                            }}
                        >
                            <ArrowForwardIos />
                        </Button>
                    ) : (
                        <>
                            <CircularProgress />
                            <IconButtonClose
                                onBtnClick={() => cancelDaliScan(daliCtlObj, dispatch)}
                            />
                        </>
                    )}
                </DivSpaceBetween>
            </DivExist>
            <Divider10 />
            <Typography variant="h6" align="center">
                Other
            </Typography>
            <Divider10 />
            <SpaceBetweenButton
                title="Query all light level"
                btnContent={<ArrowForwardIos />}
                onBtnClick={async () => await daliQueryLightLevel(daliCtlObj, 99)}
                disabled={Object.keys(lightObj || {}).length === 0}
            />
            <SpaceBetweenButton
                title="Query all light lock level"
                btnContent={<ArrowForwardIos />}
                onBtnClick={async () => await daliQueryLightLockLevel(daliCtlObj, 99)}
                disabled={Object.keys(lightObj || {}).length === 0}
            />
            {/* <DivExist show={userObj.level < 1}>
                <SpaceBetweenButton
                    title="Remove all lights"
                    btnContent={<ArrowForwardIos />}
                    color="secondary"
                    disabled={Object.keys(lightObj || {}).length === 0}
                    onBtnClick={async () => {
                        confirmWindow(
                            dispatch,
                            "Confirm remove all lights?",
                            async () => await daliDeleteAllLights(daliCtlObj)
                        );
                    }}
                />
            </DivExist> */}
            {/* <SpaceBetweenButton
                title="Remove all sensors"
                btnContent={<ArrowForwardIos />}
                color="secondary"
                disabled={Object.keys(sensorObj || {}).length === 0}
                onBtnClick={async () => {
                    confirmWindow(
                        dispatch,
                        "Confirm remove all sensors?",
                        async () => await daliDeleteAllSensors(daliCtlObj)
                    );
                }}
                hideComponent={daliMasterModel?.includes("Skymon")}
            /> */}
            <SpaceBetweenCheckbox
                title="All lights brighten to 100% when bypass is on"
                data={daliCtlObj?.cutDimOnBypass ? true : false}
                onCheck={async (e) => {
                    changeDaliCtlOneSetting(daliCtlObj, 0xa32, { cutDimOnBypass: e.target.checked ? 1 : 0 })
                }}
                handleRefresh={async () => queryDaliCtlOneSetting(daliCtlObj, 0xb1e)}
            />

            <SpaceBetweenDiv
                title="Copy/Move to other map"
                variant="outlined"
                data={
                    <CopyDeviceToMapModal
                        deviceID={activeDaliCtlID}
                        deviceType="daliCtl"
                        deviceName={description}
                    />
                }
            />
            <DivSpaceBetween style={{ marginBottom: "1vh" }}>
                <Typography>Remarks</Typography>
                <ModalRemarks
                    currentRemarks={daliCtlObj?.remarks}
                    handleSave={async (r) => {
                        await updateDaliCtlProperty(activeDaliCtlID, {
                            remarks: r,
                        });
                    }}
                    closeOnSave
                />
            </DivSpaceBetween>
            <DivExist show={daliMasterModel?.includes("Skymon")}>
                <SpaceBetweenButton
                    title="Restart"
                    btnContent="Restart"
                    variant="outlined"
                    color="secondary"
                    marginBottom={5}
                    onBtnClick={async () =>
                        confirmWindow(
                            dispatch,
                            "Confirm restart?",
                            async () => await restartDaliCtl(activeDaliCtlID, daliCtlObj)
                        )
                    }
                />
                {/* <SpaceBetweenDiv title="Firmware:" data={deviceVersion} /> */}
                <DivExist show={userObj.level < 2}>
                    <DeviceUpdateFirmwareModal deviceObj={daliCtlObj} />
                </DivExist>
            </DivExist>
        </>
    )
}