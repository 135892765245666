import React, { useState, useContext } from "react";
import { Typography, Button, Box } from "@mui/material";
import { SMContext } from "context/smContext";
import { Delete, Done } from "@mui/icons-material";
import { mgFindAll, mgFindOne, mgFindQuery, mgPost } from "actions/mongoApiActions";
import DeviceIcon from "components/DeviceIcon";
import IconButtonAdd from "components/IconButtonAdd";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import { deviceInfoOfObj } from "actions/generalActions";
import DivExist from "components/DivExist";
import AdminContainer from "components/AdminContainer";
import IconButtonDone from "components/IconButtonDone";
import IconButtonDelete from "components/IconButtonDelete";
import DivCol from "components/DivCol";
import DivInline from "components/DivInline";
import { alertWindow } from "actions/screenActions";
import { mapAddLight, mapAddSensor, mapAddGateway, mapAddDaliCtl } from "actions/mapActions";
export default function AddDeviceByAddress({ handleClose }) {
    const [state, dispatch] = useContext(SMContext);
    const { activeMapID, mapObjAll, userObj } = state;
    const [inputAddress, setInputAddress] = useState("");
    const [foundObjArray, setFoundObjArray] = useState([]);
    const handleSave = async (deviceObj, deviceType) => {
        if (deviceType === "light") {
            await mapAddLight(activeMapID, deviceObj.serial, deviceObj);
            handleClose();
        } else if (deviceType === "sensor") {
            await mapAddSensor(activeMapID, deviceObj.sensorID, deviceObj);
            handleClose();
        } else if (deviceType === "gateway") {
            await mapAddGateway(activeMapID, deviceObj.gatewayID, deviceObj);
            handleClose();
        } else if (deviceType === "daliCtl") {
            await mapAddDaliCtl(activeMapID, deviceObj.daliCtlID, deviceObj);
            handleClose();
        } else {
            alertWindow(dispatch, `Device type [${deviceType}] not supported`);
        }
    }
    const handleCheck = async () => {
        let objArr = [];
        const query = {
            $or: [
                { dtkAdd: inputAddress },
                { serial: inputAddress },
                { wifiApMacAddress: inputAddress },
                { sensorID: inputAddress },
            ]
        }
        const objArrLight = await mgFindQuery("serial", query);
        if (objArrLight.length > 0) {
            objArr = objArrLight;
        }
        const objArrSensor = await mgFindQuery("sensor", query);
        if (objArrSensor.length > 0) {
            objArr = [...objArr, ...objArrSensor];
        }
        const daliQuery = {
            $or: [
                { daliCtlID: inputAddress },
                { dtkAdd: inputAddress },
                { wifiApMacAddress: inputAddress },
            ]
        }
        const objArrDali = await mgFindQuery("daliCtl", daliQuery);
        if (objArrDali.length > 0) {
            objArr = [...objArr, ...objArrDali];
        }
        const gwQuery = {
            $or: [
                { gatewayID: inputAddress },
                { "ttyS2.meshMacAddress": inputAddress },
                { "ttyS3.meshMacAddress": inputAddress },
                { "ttyS7.macAddress": inputAddress },
            ]
        }
        const objArrGw = await mgFindQuery("gateway", gwQuery);
        if (objArrGw.length > 0) {
            objArr = [...objArr, ...objArrGw];
        }
        setFoundObjArray(objArr);
    }
    return (
        <>
            <Typography variant="h6">Add Device by Address/ID</Typography>
            <Divider10 />
            <SpaceBetweenInput
                title="Address/ID/wifiApMacAddress"
                data={inputAddress}
                width="40%"
                onInput={(evt) => setInputAddress(evt.target.value)}
            />
            <Divider10 />
            <IconButtonDone tooltip="Search by address/ID/wifiApMacAddress" onBtnClick={handleCheck} disabled={!inputAddress} />
            <IconButtonDelete tooltip="Clear" onBtnClick={() => {
                setFoundObjArray([])
                setInputAddress("");
            }} />
            <Divider10 />
            <DivInline alignItems="flex-start">
                {foundObjArray.map((obj, key) => {
                    const deviceInfo = deviceInfoOfObj(obj);
                    const deviceType = deviceInfo.deviceType;
                    const deviceStyle = deviceType === "light" ? obj.style : deviceType === "sensor" ? obj.type : "";
                    const mapDeviceObj = mapObjAll[activeMapID]?.[`${deviceInfo.deviceType}Obj`] || {};
                    const inMapDeviceObj = mapDeviceObj[deviceInfo.deviceID] ? true : false;
                    const inDeviceMapArray = (obj.mapID || []).includes(activeMapID)
                    return (
                        <DivCol key={key} sx={{ width: "15vw", outline: "1px solid #fff", padding: "5px", margin: "0.5vw" }} alignItems="center">
                            <DeviceIcon
                                deviceType={deviceType}
                                width={4}
                                sizeUnit="vw"
                                deviceStyle={deviceStyle}
                                gang={obj.gang}
                            />
                            <Typography variant="caption" display="block">Type</Typography>
                            <Typography variant="body2">{deviceType}</Typography>
                            <Typography variant="caption" display="block">ID</Typography>
                            <Typography variant="body2">{deviceInfo.deviceID}</Typography>
                            <Typography variant="caption" display="block">Address</Typography>
                            <Typography variant="body2">{obj.dtkAdd || "-"}</Typography>
                            <Typography variant="caption" display="block">WifiApMacAddress</Typography>
                            <Typography variant="body2">{obj.wifiApMacAddress || "-"}</Typography>
                            <Typography variant="caption" display="block">Name</Typography>
                            <Typography variant="body2">{deviceInfo.deviceName || "-"}</Typography>
                            {
                                inDeviceMapArray && inMapDeviceObj ?
                                    <Typography color="error" sx={{ outline: "1px solid #fff" }}>{`${deviceType} already in map`}</Typography>
                                    :
                                    <IconButtonAdd
                                        onBtnClick={async () => await handleSave(obj, deviceType)}
                                        tooltip={`Add ${obj.deviceType} to map`}
                                    />
                            }
                            <AdminContainer>
                                <DivExist show={!inDeviceMapArray && inMapDeviceObj}>
                                    <Typography>{`${deviceType} in mapObj but not in ${deviceType}Obj mapArray`}</Typography>
                                </DivExist>
                                <DivExist show={inDeviceMapArray && !inMapDeviceObj}>
                                    <Typography>{`${deviceType} in ${deviceType}Obj mapArray but not in mapObj`}</Typography>
                                </DivExist>
                            </AdminContainer>

                        </DivCol>
                    )
                })


                }
            </DivInline>
        </>
    )
}