import { Paper, Modal, Typography, Fade, Backdrop } from "@mui/material";
import Divider10 from "./Divider10";
import IconButtonClose from "./IconButtonClose";
import { dialogBg } from "asset/string/color";
import IconButtonSave from "./IconButtonSave";
import IconButtonAdd from "./IconButtonAdd";
import DivInline from "./DivInline";
import IconButtonBack from "./IconButtonBack";
import IconButtonDelete from "./IconButtonDelete";
import IconButtonDone from "./IconButtonDone";

/**
 *
 * @param {object} props
 * @param {React.Component} props.children
 * @param {boolean} props.open
 * @param {Function} props.onClose
 * @param {string} [props.modalTitle]
 * @param {JSX} [props.modalIcon]
 * @param {string|dialogBg} [props.backgroundColor]
 * @param {string} [props.minWidth]
 * @param {string} [props.minHeight]
 * @param {boolean} [props.disableBottomClose]
 * @param {string} [props.maxHeight]
 * @param {string} [props.maxWidth]
 * @param {string} [props.width]
 * @param {string} [props.height]
 * @param {Function} [props.onSave]
 * @param {Function} [props.onDone]
 * @param {Function} [props.onAdd]
 * @param {Function} [props.onDelete]
 * @param {boolean} [props.disabledSave]
 * @param {boolean} [props.disabledDone]
 * @param {boolean} [props.disabledDelete]
 * @param {500|number} [props.fadeTime]
 * @description
 * example
 *
 * <ModalSM open={open} onClose={()=>setOpen(false) minWidth="50vw"}>
 *   <div>abc</div>
 * </Modal>
 */
export default function ModalSM({
  children,
  open,
  onClose,
  modalTitle,
  modalIcon,
  backgroundColor,
  minWidth,
  minHeight,
  disableBottomClose,
  maxHeight,
  maxWidth,
  width,
  height,
  onSave,
  onDone,
  onAdd,
  onDelete,
  disabledSave,
  disabledDone,
  disabledDelete,
  fadeTime = 500,
  titleComponent,
}) {
  if (!open) return null;
  return (
    <Modal
      open={open}
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {open ? (
        <Fade in={open} timeout={{ enter: fadeTime, exit: fadeTime }}>
          <Paper
            style={{
              position: "absolute",
              background: backgroundColor || dialogBg,
              minWidth: minWidth || "",
              minHeight: minHeight || "",
              padding: "0.5vw",
              top: "50%",
              left: "50%",
              boxShadow: "2px 3px darkgrey",
              transform: "translate(-50%,-50%)",
              maxHeight: maxHeight || "",
              maxWidth: maxWidth || "",
              width: width || minWidth || "50vw",
              height: height || minHeight || "40vh",
              overflow: "auto",
            }}
          >
            {modalTitle && (
              <>
                <DivInline>
                  <IconButtonBack onBtnClick={() => onClose()} />
                  {modalIcon}
                  <Typography sx={{ marginLeft: "1vw" }}>
                    {modalTitle}
                  </Typography>
                  <span style={{ marginLeft: "1vw" }}>{titleComponent}</span>
                </DivInline>
                <Divider10 />
              </>
            )}
            {children}

            {!disableBottomClose && (
              <>
                <Divider10 />
                <DivInline justifyContent="flex-end">
                  <IconButtonClose onBtnClick={onClose} />
                  {onDelete && (
                    <IconButtonDelete
                      onBtnClick={onDelete}
                      disabled={disabledDelete}
                    />
                  )}
                  {onSave && (
                    <IconButtonSave
                      onBtnClick={onSave}
                      disabled={disabledSave}
                    />
                  )}
                  {onDone && (
                    <IconButtonDone
                      onBtnClick={onDone}
                      disabled={disabledDone}
                    />
                  )}
                  {onAdd && <IconButtonAdd onBtnClick={onAdd} />}
                </DivInline>
              </>
            )}
          </Paper>
        </Fade>
      ) : (
        <></>
      )}
    </Modal>
  );
}
