import { useState } from "react"
import ModalSM from "./ModalSM";
import { Box, Typography } from "@mui/material";
import DivExist from "./DivExist";
import DivInline from "./DivInline";
import General from "@ecoenghk/general";
const gs = new General();

export default function JsonModalContainer({ children, jsonData = {}, disabled }) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div onClick={() => setOpen(true)}>{children}</div>
            {!disabled ?
                <ModalSM
                    open={open}
                    onClose={() => setOpen(false)}
                    backgroundColor="#212F3C"
                    modalTitle="JSON Data"
                    height="80vh"
                    width="90vw"
                >
                    {Object.keys(jsonData || {})
                        .sort((a, b) => a.localeCompare(b))
                        .map((key) => {
                            let type = "string";
                            const value = jsonData[key];
                            if (typeof value === "boolean") type = "boolean";
                            if (!Array.isArray(value) && typeof value === "object") {
                                if (gs.isEmptyJson(value)) type = "emptyJson";
                                else type = "json";
                            }
                            if (Array.isArray(value)) type = "array";
                            return (
                                <DivInline
                                    justifyContent="space-between"
                                    key={key}
                                    alignItems="flex-start"
                                    sx={{ borderBottom: "1px solid #fff" }}
                                >
                                    <Typography variant="body2" sx={{ width: "16vw" }}>
                                        {key} [{type}]
                                    </Typography>
                                    <Box sx={{ width: "64vw" }}>
                                        <DivExist show={type === 'boolean'}>
                                            <Typography variant="body2" >{value?.toString()}</Typography>
                                        </DivExist>
                                        <DivExist show={type === 'string'}>
                                            <Typography variant="body2" >{value}</Typography>
                                        </DivExist>
                                        {
                                            type === "array" &&
                                            <>
                                                {

                                                    (value || []).map((item, index) => {
                                                        return (
                                                            <Typography variant="body2" key={index}>{item}</Typography>
                                                        );
                                                    })
                                                }

                                            </>
                                        }
                                        <DivExist show={type === "json"}>
                                            {
                                                Object.keys(value || {})
                                                    .sort((a, b) => a.localeCompare(b))
                                                    .map((key1, index) => {
                                                        return (
                                                            <DivInline key={index}>
                                                                <Box sx={{ width: "15vw", wordBreak: "break-word" }}>
                                                                    <Typography variant="body2">{key1}</Typography>
                                                                </Box>
                                                                <Box sx={{ width: "49vw", wordBreak: "break-word" }}>
                                                                    <Typography variant="body2" >{JSON.stringify(value[key1])}</Typography>
                                                                </Box>
                                                            </DivInline>
                                                        );
                                                    })
                                            }

                                        </DivExist>
                                        <DivExist show={type === "emptyJson"}>
                                            <Typography variant="body2" >{"{ }"}</Typography>
                                        </DivExist>
                                    </Box>
                                </DivInline>
                            );
                        })}
                </ModalSM>
                :
                null
            }
        </>
    )
}