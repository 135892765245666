import { SMContext } from "context/smContext";
import { ArrowForwardIos, DataObject, Search } from "@mui/icons-material";
import { mgDeleteMany, mgFindAll, mgUpdateOneUpsert } from "actions/mongoApiActions";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import { useState, useRef, useContext, useEffect } from "react";
import { ViewportList } from "react-viewport-list";
import { Box, Typography, Button, TextField, MenuItem } from "@mui/material";
import IconButtonDelete from "components/IconButtonDelete";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { isMatch } from "actions/generalActions";
import DivInline from "components/DivInline";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import AdminContainer from "components/AdminContainer";
import JsonModal from "components/JsonModal";
import ListSortButton from "components/ListSortButton";
import { deleteUser } from "actions/userActions";

const cw = ["25%", "25%", "15%", "5%", "5%", "7%", "15%"];

export default function AllUserManagementModal() {
    const [state, dispatch] = useContext(SMContext);
    const ref = useRef(null);
    const [open, setOpen] = useState(false);
    const [userArray, setUserArray] = useState([]);
    const [userArrayUse, setUserArrayUse] = useState([]);
    const [sortField, setSortField] = useState("company");
    const [sortOrder, setSortOrder] = useState("asc");
    const [searchText, setSearchText] = useState("");
    const [filterLv, setFilterLv] = useState(99);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const handleOpen = async () => {
        setOpen(true);
        let arr = await mgFindAll("user");
        arr = arr.sort((a, b) => a.email?.localeCompare(b.email));
        setUserArray(arr);
    }
    const handleDeleteUser = async (obj) => {
        confirmWindow(dispatch, `Delete user ${obj.email}?`, async () => {
            const fellowUserIdArray = userArray.filter(user => user.masterUserUid === obj.uid);
            if (fellowUserIdArray.length > 0) {
                alertWindow(dispatch, `This user has ${fellowUserIdArray.length} fellow users. Please delete them first.`);
                return;
            } else {
                deleteUser(obj);
            }
        });
    }
    useEffect(() => {
        let arr = userArray;
        if (searchText !== "") {
            arr = userArray.filter((obj) =>
                isMatch(obj?.email || "", searchText)
                || isMatch(obj?.uid || "", searchText)
                || isMatch(obj?.name || "", searchText)
                || isMatch(obj?.password || "", searchText)
                || isMatch(String(obj?.level) || "", searchText));
        }
        if (filterLv < 99) {
            arr = arr.filter((obj) => obj.level === filterLv);
        }
        if (sortOrder === "asc") {
            arr = arr.sort((a, b) => String(a[sortField])?.localeCompare(String(b[sortField])));
        } else {
            arr = arr.sort((a, b) => String(b[sortField])?.localeCompare(String(a[sortField])));
        }
        setUserArrayUse(arr);
    }
        , [searchText, userArray, filterLv, sortField, sortOrder]);
    function SortTitle({ title, width, field }) {
        return (
            <Typography variant="caption" sx={{ width }}>
                <ListSortButton
                    title={title}
                    field={field}
                    sortBy={sortField}
                    sortOrder={sortOrder}
                    onAsc={() => {
                        setSortField(field);
                        setSortOrder("asc");
                    }}
                    onDesc={() => {
                        setSortField(field);
                        setSortOrder("desc");
                    }}
                />
            </Typography>
        );
    }
    return (
        <>
            <Button onClick={handleOpen} variant="outlined"><ArrowForwardIos /></Button>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle="All User Management"
                width="85vw"
                height="80vh"

                titleComponent={
                    <DivInline>
                        <Search />
                        <TextField value={searchText || ""} onChange={(e) => setSearchText(e.target.value)} label="Search" variant="outlined" size="small" />
                        <IconButtonDelete size="small" tooltip="Clear search" onBtnClick={() => setSearchText("")} />
                        <TextField select label="Filter level" value={filterLv ?? 99} onChange={(e) => setFilterLv(e.target.value)} variant="outlined" size="small" sx={{ width: "8vw", marginLeft: "1vw" }}>
                            <MenuItem value={99}>All</MenuItem>
                            <MenuItem value={0}>0</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                        </TextField>
                    </DivInline>
                }
            >
                <div className="scroll-container" ref={ref} style={{ width: "100%" }}>
                    <Box sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid grey", borderTop: "1px solid grey" }}>
                        <SortTitle title="email" width={cw[0]} field="email" />
                        <SortTitle title="ID" width={cw[1]} field="uid" />
                        <SortTitle title="name" width={cw[2]} field="name" />
                        <SortTitle title="level" width={cw[3]} field="level" />
                        <div style={{ width: cw[4] }}></div>
                        <div style={{ width: cw[5] }}></div>
                        <Typography variant="caption" sx={{ width: cw[6] }}>master Uid</Typography>

                    </Box>
                    {
                        userArrayUse.map((obj, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        borderBottom: "1px solid grey",
                                        "&:hover": { background: "#1B2631" },
                                    }}
                                    onClick={() => {
                                        setSelectedUser(obj);
                                        setOpenEditModal(true);
                                    }}
                                >
                                    <Typography variant="caption" sx={{ width: cw[0] }}>{obj.email}</Typography>
                                    <Typography variant="caption" sx={{ width: cw[1] }}>{obj.uid}</Typography>
                                    <Typography variant="caption" sx={{ width: cw[2] }}>{obj.name}</Typography>
                                    <Typography variant="caption" sx={{ width: cw[3] }}>{obj.level}</Typography>
                                    <div style={{ width: cw[4] }}>
                                        <IconButtonDelete
                                            onBtnClick={async (e) => {
                                                e.stopPropagation();
                                                await handleDeleteUser(obj)
                                            }} size="small" tooltip="Delete user" />
                                    </div>
                                    <div style={{ width: cw[5] }}><JsonModal show jsonData={obj} btnContent={<DataObject />} /></div>
                                    <Typography variant="caption" sx={{ width: cw[6] }}>{obj.masterUserUid}</Typography>
                                </Box>
                            )
                        })
                    }

                    {/* <ViewportList viewportRef={ref} items={userArrayUse}>
                        {(obj, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        borderBottom: "1px solid grey",
                                        "&:hover": { background: "#1B2631" },
                                    }}
                                    onClick={() => {
                                        setSelectedUser(obj);
                                        setOpenEditModal(true);
                                    }}
                                >
                                    <Typography variant="caption" sx={{ width: cw[0] }}>{obj.email}</Typography>
                                    <Typography variant="caption" sx={{ width: cw[1] }}>{obj.uid}</Typography>
                                    <Typography variant="caption" sx={{ width: cw[2] }}>{obj.name}</Typography>
                                    <Typography variant="caption" sx={{ width: cw[3] }}>{obj.level}</Typography>
                                    <div style={{ width: cw[4] }}>
                                        <IconButtonDelete
                                            onBtnClick={async (e) => {
                                                e.stopPropagation();
                                                await handleDeleteUser(obj)
                                            }} size="small" tooltip="Delete user" />
                                    </div>
                                    <div style={{ width: cw[5] }}><JsonModal show jsonData={obj} btnContent={<DataObject />} /></div>
                                    <Typography variant="caption" sx={{ width: cw[6] }}>{obj.masterUserUid}</Typography>
                                </Box>

                            )
                        }}
                    </ViewportList> */}
                </div>
                <EditModal userObj={selectedUser} open={openEditModal} setOpen={setOpenEditModal} dispatch={dispatch} refresh={handleOpen} />
            </ModalSM>
        </>
    )
}

function EditModal({ userObj, open, setOpen, dispatch, refresh }) {
    const [entry, setEntry] = useState({});
    useEffect(() => {
        if (userObj && open) {
            setEntry(userObj);
        }
    }, [userObj, open])
    const editEntry = (key, val) => {
        const newEntry = { ...entry, [key]: val };
        setEntry(newEntry);
    }
    const handleSave = async () => {
        let saveObj = { ...entry };
        delete saveObj._id;
        const res = await mgUpdateOneUpsert("user", { uid: saveObj.uid }, saveObj);
        console.log(res);
        if (res.success) {
            alertWindow(dispatch, "User saved");
            refresh();
        }

    }
    if (!userObj) return null;
    return (
        <ModalSM open={open} onClose={() => setOpen(false)} modalTitle="Edit User" width="50vw" height="50vh" onSave={handleSave}>
            <SpaceBetweenDiv title="User" data={userObj.email} />
            <SpaceBetweenDiv title="uid" data={userObj.uid} />
            <SpaceBetweenInput title="Name" data={entry.name || ""} onInputValue={val => editEntry("name", val)} />
            <SpaceBetweenInput title="Password" data={entry.password || ""} onInputValue={val => editEntry("password", val)} />
            <SpaceBetweenSelect title="Level" data={entry.level ?? 3} onChangeValue={val => editEntry("level", Number(val))}
                menuObj={{ 0: "0", 1: "1", 2: "2", 3: "3" }}
            />
        </ModalSM>
    )
}