import { useState, useContext, useMemo, useEffect, useRef } from "react";
import { AdminPanelSettings } from "@mui/icons-material";
import { Typography, Button, Tooltip, TextField, Box } from "@mui/material";
import { SMContext } from "context/smContext";
import ModalSM from "components/ModalSM";
import {
  mgDeleteMany,
  mgFindAll,
  mgUpdateOneUpsert,
  mgDeleteDocField,
  mgArrayRemove,
  mgFindQuery,
} from "actions/mongoApiActions";
import IconButtonDelete from "components/IconButtonDelete";
import {
  alertWindow,
  confirmWindow,
  promptWindow,
} from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import DialogInput from "components/DialogInput";
import DivInline from "components/DivInline";
import DivExist from "components/DivExist";
import IconButtonAdd from "components/IconButtonAdd";
import moment from "moment";
import General from "@ecoenghk/general";
import Divider10 from "components/Divider10";
import MapTopBtn from "containers/home/MapTopBtn";
import { ViewportList } from "react-viewport-list";
import IconButtonSave from "components/IconButtonSave";
import MongoLogo from "asset/svgComp/MongoLogo";
import ButtonSM from "components/ButtonSM";

const gs = new General();
const tStyle = {
  border: `1px solid #515A5A`,
  borderCollapse: "collapse",
  fontSize: "small",
  padding: "2px",
};
const majorKeyObj = {
  currentStatus: "serial",
  user: "email",
  map: "mapID",
  serial: "serial",
  sensor: "sensorID",
  gwLog: "timeString",
  timeTable: "timeTableID",
  serLog: "timeString",
  sensorLogs: "timeString",
  report: "reportID",
  schedule_oneTime: "scheduleID",
  gateway: "gatewayID",
  daliCtl: "daliCtlID",
  deviceStatus: "_id",
  server: "serverID",
  rssi: "docID",
  mikeTest: "gatewayID",
  zoneControl: "zoneControlID",
  test: "testID",
  test2: "testID",
  publicHoliday: "shortDate",
  deviceLogs: "timeStamp",
  daliCli: "dtkAdd"
};
export default function MongoAdminModal({ isMapButton }) {
  const [state, dispatch] = useContext(SMContext);
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [collList, setCollList] = useState([]);
  const [activeCol, setActiveCol] = useState("");
  const [docObj, setDocObj] = useState([]);
  const [searchQuery, setSearchQuery] = useState({});
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [showDoc, setShowDoc] = useState({});
  const [selectDate, setSelectDate] = useState(moment().format("YYYY-MM-DD"));
  const [fieldArray, setFieldArray] = useState([]);
  const handleOpen = async () => {
    setOpen(true);
    const res = await fetch(`${global.ip}/api/getMgCollectionList`);
    const arrJson = await res.json();
    console.log(arrJson);
    let arr = arrJson.collectionList;
    arr = arr.sort((a, b) => a?.localeCompare(b));
    setCollList(arr);
  };
  const getCollection = async (col) => {
    setActiveCol(col);
    let arr;
    if (col.includes("log") || col.includes("Log")) {
      const startTsp = moment(selectDate).startOf("day");
      const endTsp = moment(selectDate).endOf("day");
      arr = await mgFindQuery(col, {
        timeStamp: { $gte: startTsp, $lte: endTsp },
      });
    } else {
      arr = await mgFindAll(col);
    }

    const majorKey = majorKeyObj[col] || "_id";
    let objAll = {};
    let fieldArr = [];
    arr.forEach((obj) => {
      const majorKeyVal = obj[majorKey];
      objAll = { ...objAll, [majorKeyVal]: obj };
      Object.keys(obj).forEach((k) => {
        if (!fieldArr.includes(k)) fieldArr = [...fieldArr, k];
      });
    });
    console.log(objAll);
    setDocObj(objAll);
    setFieldArray(fieldArr);
  };
  const docObjF = useMemo(() => {
    let arr = Object.keys(docObj || {}).map((key) => docObj[key]);
    if (!gs.isEmptyJson(searchQuery)) {
      arr = arr.filter((doc) => {
        let match = true;
        Object.keys(searchQuery || {}).forEach((searchKey) => {
          const searchVal = searchQuery[searchKey];
          if (!String(doc[searchKey])?.includes(String(searchVal))) {
            match = false;
          }
        });
        return match;
      });
    }

    const majorKey = majorKeyObj[activeCol] || "_id";
    if (majorKey) {
      if (order === "asc")
        arr = arr.sort((a, b) =>
          a?.[majorKey]?.toString()?.localeCompare(b?.[majorKey]?.toString())
        );
      else
        arr = arr.sort((a, b) =>
          b?.[majorKey]?.toString()?.localeCompare(a?.[majorKey]?.toString())
        );
    }
    let objAll = {};
    arr.forEach((obj) => {
      objAll = { ...objAll, [obj[majorKey]]: obj };
    });
    return objAll;
  }, [activeCol, docObj, searchQuery, orderBy, order]);

  const handleDeleteDoc = async (key) => {
    const majorKeyName = majorKeyObj[activeCol] || "_id";
    confirmWindow(
      dispatch,
      `Confirm delete [${activeCol}] document of ${majorKeyName}- ${key}`,
      async () => {
        const query = {
          $or: [
            { [majorKeyName]: Number(key) },
            { [majorKeyName]: String(key) },
          ],
        };
        // console.log("delete", activeCol, query);
        const result = await mgDeleteMany(activeCol, query);
        // console.log(result);
        await gs.waitFor(500);
        await getCollection(activeCol);
      }
    );
  };

  return (
    <>
      {isMapButton ? (
        <MapTopBtn btnClick={handleOpen} tooltip={"Mongo Admin"}>
          <div><MongoLogo width={3.1} sizeUnit="vh" /></div>
        </MapTopBtn>
      ) : (
        <Tooltip title="Mongo Admin">
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={handleOpen}
          >
            <MongoLogo width={3.1} sizeUnit="vh" />
          </Button >
        </Tooltip>

      )
      }
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        minHeight="95vh"
        maxHeight="95vh"
        minWidth="96vw"
        maxWidth="96vw"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <MongoLogo width={5} sizeUnit="vh" />
          <Typography sx={{ marginRight: "2vw" }}>Mongo Admin</Typography>
          <SearchModal
            handleSave={(q) => setSearchQuery(q)}
            activeCol={activeCol}
            fieldArray={fieldArray}
          />
          <DivExist
            show={activeCol.includes("log") || activeCol.includes("Log")}
          >
            <TextField
              type="date"
              size="small"
              value={selectDate}
              onChange={(e) => {
                setSelectDate(e.target.value);
                getCollection(activeCol);
              }}
            />
          </DivExist>
        </DivInline>

        <div style={{ marginBottom: "1vh" }}>
          {(collList || []).map((col) => (
            <Button
              key={col}
              size="small"
              onClick={() => getCollection(col)}
              color={col === activeCol ? "secondary" : "primary"}
              variant={col === activeCol ? "contained" : "outlined"}
            >
              {col}
            </Button>
          ))}
        </div>
        <table
          style={{
            margin: "auto",
            width: "100%",
            ...tStyle,
          }}
        >
          <tbody className="scroll-container" ref={ref}>
            <ViewportList viewportRef={ref} items={Object.keys(docObjF || {})}>
              {(key, k) => {
                const val1 = docObj[key];
                return (
                  <tr key={k} style={tStyle}>
                    <td style={tStyle}>
                      {key}
                      {showDoc[key] ? (
                        <button
                          style={{ width: "2.5vw", margin: "0 1vw" }}
                          onClick={() => {
                            let newShowDoc = { ...showDoc, [key]: false };
                            setShowDoc(newShowDoc);
                          }}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          style={{ width: "2.5vw", margin: "0 1vw" }}
                          onClick={() => {
                            let newShowDoc = { ...showDoc, [key]: true };
                            setShowDoc(newShowDoc);
                          }}
                        >
                          +
                        </button>
                      )}
                      <button onClick={() => handleDeleteDoc(key)}>
                        delete
                      </button>
                    </td>
                    <td style={tStyle}>
                      {showDoc[key] ? (
                        <JsonTable
                          jdata={val1}
                          level={0}
                          activeCol={activeCol}
                          query={{ [majorKeyObj[activeCol]]: key }}
                          getCollection={getCollection}
                          updateKey={""}
                        />
                      ) : (
                        <span>...</span>
                      )}
                      {/* {JSON.stringify(val1)} */}
                    </td>
                  </tr>
                );
              }}
            </ViewportList>
          </tbody>
        </table>
      </ModalSM>
    </>
  );
}

function JsonTable({
  jdata,
  level,
  activeCol,
  query,
  getCollection,
  updateKey,
}) {
  const [state, dispatch] = useContext(SMContext);
  const [showDetail, setShowDetail] = useState({});
  const editVal = async (key, val, dataType) => {
    const updateVal =
      dataType === "boolean"
        ? Boolean(val)
        : dataType === "number"
          ? Number(val)
          : String(val);
    const keyPath = updateKey ? `${updateKey}.${key}` : key;
    const updateObj = { [keyPath]: updateVal };
    console.log({ activeCol, key, val, query, updateKey, updateObj });
    await mgUpdateOneUpsert(activeCol, query, updateObj);
    await gs.waitFor(500);
    await getCollection(activeCol);
  };
  const handleDeleteField = async (keyPath) => {
    confirmWindow(dispatch, `Delete field [${keyPath}]?`, async () => {
      console.log("delete", query, keyPath);
      await mgDeleteDocField(activeCol, query, { [keyPath]: "" });
      await getCollection(activeCol);
    });
  };
  const handleArrayAddItem = async (arr, keyPath) => {
    let nextIndex;
    if (arr.length === 1 && arr[0] === null) {
      console.log("empty arr");
      nextIndex = 0;
    } else if (arr.length === 1 && arr[0] !== null) {
      console.log("arr has one item");
      nextIndex = 1;
    } else if (arr.length === 0) {
      nextIndex = 0;
    } else if (arr.length > 1) {
      nextIndex = arr.length;
    }
    promptWindow(
      dispatch,
      "array item type: string | number ?",
      async (valType) => {
        if (valType === "string" || valType === "number") {
          await gs.waitFor(500);
          promptWindow(dispatch, "new value", async (newVal) => {
            if (valType === "number") {
              await mgUpdateOneUpsert(activeCol, query, {
                [`${keyPath}.${nextIndex}`]: Number(newVal),
              });
            } else if (valType === "string") {
              await mgUpdateOneUpsert(activeCol, query, {
                [`${keyPath}.${nextIndex}`]: newVal,
              });
            }
            await getCollection(activeCol);
          });
        } else {
          alertWindow(dispatch, "Wrong data type");
        }
      }
    );
  };
  const handleAddJsonField = async (updateKey) => {
    promptWindow(
      dispatch,
      "+ Data type ( number | string | boolean | array | json )?",
      async (type) => {
        if (
          type === "number" ||
          type === "string" ||
          type === "boolean" ||
          type === "array" ||
          type === "json"
        ) {
          await gs.waitFor(500);
          promptWindow(dispatch, "Key?", async (newKey) => {
            const keyPath = updateKey ? `${updateKey}.${newKey}` : newKey;
            if (type === "string") {
              await mgUpdateOneUpsert(activeCol, query, {
                [keyPath]: "",
              });
            } else if (type === "number") {
              await mgUpdateOneUpsert(activeCol, query, {
                [keyPath]: 0,
              });
            } else if (type === "boolean") {
              await mgUpdateOneUpsert(activeCol, query, {
                [keyPath]: false,
              });
            } else if (type === "array") {
              await mgUpdateOneUpsert(activeCol, query, {
                [keyPath]: [],
              });
            } else if (type === "json") {
              await gs.waitFor(500);
              promptWindow(dispatch, "json key?", async (newSubKey) => {
                const subObj = { [newSubKey]: "" };
                await mgUpdateOneUpsert(activeCol, query, {
                  [keyPath]: subObj,
                });
              });
            }
            await getCollection(activeCol);
          });
        } else {
          alertWindow(dispatch, "Wrong data type");
        }
      }
    );
  };
  return (
    <table style={tStyle}>
      <tbody>
        {Object.keys(jdata || {})
          .sort((a, b) => a?.localeCompare(b))
          .map((key, k) => {
            const val = jdata[key];
            let dataType;
            if (Array.isArray(val)) {
              dataType = "array";
            } else if (typeof val === "object" && val !== null) {
              dataType = "object";
            } else if (typeof val === "number") {
              dataType = "number";
            } else if (typeof val === "string") {
              dataType = "string";
            } else if (typeof val === "boolean") {
              dataType = "boolean";
            } else {
              dataType = "";
            }
            const keyPath = updateKey ? `${updateKey}.${key}` : key;

            return (
              <tr key={k} style={tStyle}>
                <td style={tStyle}>
                  {key}
                  {(dataType === "array" || dataType === "object") && (
                    <button
                      onClick={() => {
                        const newStatus = !showDetail[k];
                        setShowDetail({ ...showDetail, [k]: newStatus });
                      }}
                    >
                      {showDetail[k] ? "-" : "+"}
                    </button>
                  )}
                  <IconButtonDelete
                    size="small"
                    onBtnClick={() => handleDeleteField(keyPath)}
                  />
                </td>
                {(dataType === "" ||
                  dataType === "number" ||
                  dataType === "string") && (
                    <td style={tStyle}>
                      {key === "_id" ? (
                        <span>{val}</span>
                      ) : (
                        <DivInline>
                          <DialogInput
                            showValue
                            title={`keyPath:${keyPath} [${dataType}]`}
                            size="small"
                            initialVal={String(val)}
                            handleSave={(v) => editVal(key, v, dataType)}
                            showValueVariant="caption"
                          />
                          {/* <IconButtonDelete
                        size="small"
                        onBtnClick={async () => handleDeleteField(keyPath)}
                      /> */}
                        </DivInline>
                      )}
                    </td>
                  )}
                {dataType === "boolean" && (
                  // <td style={tStyle}>{val ? "TRUE" : "FALSE"}</td>
                  <td style={tStyle}>
                    <Button
                      size="small"
                      variant={val ? "outlined" : ""}
                      onClick={() => editVal(key, true, dataType)}
                    >
                      TRUE
                    </Button>
                    <Button
                      size="small"
                      variant={!val ? "outlined" : ""}
                      onClick={() => editVal(key, false, dataType)}
                    >
                      FALSE
                    </Button>
                  </td>
                )}
                {dataType === "array" && (
                  <td style={tStyle}>
                    <DivExist show={showDetail[k]}>
                      {val.map((arrItem, i) => {
                        const itemDataType = typeof arrItem;
                        return (
                          <div key={i}>
                            <span>{`[${i}] `}</span>
                            <DialogInput
                              showValue
                              title={`keyPath:${keyPath} [${dataType}]`}
                              size="small"
                              initialVal={arrItem}
                              handleSave={(v) =>
                                editVal(`${key}.${i}`, v, itemDataType)
                              }
                              showValueVariant="caption"
                            />
                            <IconButtonDelete
                              size="small"
                              onBtnClick={async () => {
                                await mgArrayRemove(activeCol, query, keyPath, [
                                  arrItem,
                                ]);
                                await getCollection(activeCol);
                              }}
                            />
                          </div>
                        );
                      })}
                      <div>
                        <button
                          onClick={async () =>
                            await handleArrayAddItem(val, keyPath)
                          }
                        >
                          Add array item
                        </button>
                      </div>
                    </DivExist>

                    {!showDetail[k] && <span>...array</span>}
                  </td>
                )}
                {dataType === "object" && (
                  <td style={tStyle}>
                    <DivExist show={showDetail[k]}>
                      <JsonTable
                        jdata={val}
                        level={level + 1}
                        activeCol={activeCol}
                        query={query}
                        getCollection={getCollection}
                        updateKey={updateKey ? `${updateKey}.${key}` : key}
                      />
                    </DivExist>
                    {!showDetail[k] && <span>...json</span>}
                  </td>
                )}
              </tr>
            );
          })}
        <tr>
          <td colSpan={2} style={{ ...tStyle, textAlign: "center" }}>
            <button
              onClick={async () => {
                handleAddJsonField(updateKey);
              }}
            >
              + Add json field
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function SearchModal({ handleSave, activeCol, fieldArray }) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState({});
  useEffect(() => {
    const majorKey = majorKeyObj[activeCol] || "_id";
    setQuery({ [majorKey]: "" });
  }, [activeCol]);
  const save = () => {
    handleSave(query);
    setOpen(false);
  };
  return (
    <>
      <DivInline>
        <button style={{ marginRight: "1vw" }} onClick={() => setOpen(true)}>
          search
        </button>
        <div style={{ marginRight: "1vw" }}>{JSON.stringify(query || {})}</div>
        <button
          onClick={() => {
            setQuery({});
            handleSave({});
          }}
        >
          clear
        </button>
      </DivInline>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle="Search documents"
        onSave={save}
        height="85vh"
        width="85vw"
        titleComponent={<IconButtonSave onBtnClick={save} />}
      >
        {Object.keys(query || {}).map((k, key) => {
          return (
            <DivInline key={key}>
              <TextField
                label="search key"
                value={k}
                onChange={(e) => {
                  const searchKey = e.target.value;
                  const v = query[k];
                  let newQuery = { ...query };
                  delete newQuery[k];
                  newQuery[searchKey] = v;
                  setQuery(newQuery);
                }}
                variant="outlined"
              />
              <TextField
                label="search value"
                value={query[k]}
                onChange={(e) => {
                  let newQuery = { ...query, [k]: e.target.value };
                  setQuery(newQuery);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") save()
                }}
                variant="outlined"
              />
              <IconButtonDelete
                onBtnClick={() => {
                  let newQuery = { ...query };
                  delete newQuery[k];
                  setQuery(newQuery);
                }}
              />
            </DivInline>
          );
        })}
        <IconButtonAdd
          onBtnClick={() => {
            let newKey = "newKey";
            if (gs.isEmptyJson(query)) newKey = majorKeyObj[activeCol] || "_id";
            let newQuery = { ...query, [newKey]: "" };
            setQuery(newQuery);
          }}
        />
        <Divider10 />
        <Typography>Fields in collection [{activeCol}]:</Typography>
        <Divider10 />
        <Box
          sx={{
            maxHeight: "50vh",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            overflowY: "auto",
          }}
        >
          {(fieldArray || []).sort((a, b) => a?.localeCompare(b)).map((f, key) => {
            return (
              <Typography
                sx={{ margin: "0.5vw" }}
                key={key}
                variant="body2"
              >
                {f}
              </Typography>
            );
          })}
        </Box>
      </ModalSM>
    </>
  );
}
