import { useContext, useState, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Space10 from "components/Space10";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import DialogInput from "components/DialogInput";
import LightIcon from "components/LightIcon";
import ModalSM from "components/ModalSM";
import { secToMin } from "actions/generalActions";
import DivCol from "components/DivCol";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import DivExist from "components/DivExist";
import DialogInputMinSec from "components/DialogInputMinSec";
import Intermediate from "asset/svgComp/Intermediate";
import { alertWindow, openSnackbar } from "actions/screenActions";
import { showBigProgress, hideBigProgress } from "actions/screenActions";
import General from "@ecoenghk/general";
import { updateZoneControlDelayTime, zoneControlUpdateAllLightSetting } from "actions/zcActions";
import ButtonSM from "components/ButtonSM";
import ZoneControlIcon from "components/ZoneControlIcon";
const gs = new General();

export default function ZcDetailSceneBtnDelayPanel() {
  const [state, dispatch] = useContext(SMContext);
  const [openEdit, setOpenEdit] = useState(false);

  const { activeZoneControlID, zoneControlObjAll } = state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  const { delaySec, delaySec2, enable_delayed_action, enable_delayed_action2 } = zcObj;
  return (
    <div>
      <DivInline justifyContent="center">
        <Typography variant="h6" display="block" align="center">
          Delay Setting
        </Typography>
      </DivInline>
      <Divider10 />
      <ButtonSM onClick={() => setOpenEdit(true)}>
        <ZoneControlIcon
          type={zcObj.type}
          width={2.5}
          sizeUnit="vw"
          status={1}
        />
        <ArrowForward sx={{ fontSize: "1rem" }} />
        <DivExist show={enable_delayed_action || delaySec > 0}>
          <LightIcon
            tooltip="light action on press"
            width={2.5}
            sizeUnit="vw"
            statusA={0}
            lightLv={100}
            disabledConnectStatus
          />
          <Typography variant="body2">
            {secToMin(delaySec)}
          </Typography>
          <ArrowForward sx={{ fontSize: "1rem" }} />
          <LightIcon
            tooltip="delayed light action"
            width={2.5}
            sizeUnit="vw"
            statusA={0}
            lightLv={50}
            disabledConnectStatus
          />
          <DivExist show={enable_delayed_action2 || delaySec2 > 0}>
            <Typography variant="body2">
              {secToMin(delaySec2)}
            </Typography>
            <ArrowForward sx={{ fontSize: "1rem" }} />
            <LightIcon
              tooltip="delayed light action step 2"
              width={2.5}
              sizeUnit="vw"
              statusA={0}
              lightLv={30}
              disabledConnectStatus
            />
          </DivExist>
        </DivExist>
        <DivExist show={!delaySec && !delaySec2}>
          <Typography variant="body2">No delayed light action</Typography>
        </DivExist>
      </ButtonSM>
      <EditModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        zcObj={zcObj}
      />
      <Divider10 />
    </div>
  );
}
function EditModal({ open, onClose, zcObj }) {
  const [state, dispatch] = useContext(SMContext);
  const [delayT1, setDelayT1] = useState(0);
  const [delayT2, setDelayT2] = useState(0);
  useEffect(() => {
    setDelayT1(zcObj.delaySec || zcObj.delayed_action || 0);
    setDelayT2(zcObj.delaySec2 || zcObj.delayed_action2 || 0);
  }, [open]);

  const handleSave = async () => {
    showBigProgress(dispatch);
    await updateZoneControlDelayTime(zcObj, delayT1 || 0, delayT2 || 0);
    hideBigProgress(dispatch);
    onClose();
  };
  return (
    <ModalSM
      open={open}
      onClose={onClose}
      width="85vw"
      height="50vh"
      onSave={handleSave}
      modalTitle="Delay setting"
    >
      <SpaceBetweenCheckbox
        title="delay seconds"
        data={delayT1 > 0 ? true : false}
        onCheck={async (e) => {
          const val = e.target.checked ? 1 : 0;
          if (val === 0) {
            setDelayT1(0);
            setDelayT2(0);
          }
          if (val === 1 && !delayT1) setDelayT1(10);
        }}
      />
      <SpaceBetweenCheckbox
        title="2-step delay seconds"
        data={delayT2 > 0 ? true : false}
        onCheck={async (e) => {
          const val = e.target.checked ? 1 : 0;
          if (val === 0) setDelayT2(0);
          if (val === 1) {
            if (delayT1 === 0) setDelayT1(10);
            if (delayT2 === 0) setDelayT2(15);
          }
        }}
      />
      <Divider10 />
      <DivInline justifyContent="space-around">
        <Space10 space="2vw" />
        <ZoneControlIcon type={zcObj.type} width={8} sizeUnit="vw" status={1} />
        <ArrowForward sx={{ fontSize: "1.2rem" }} />
        <DivCol>
          <LightIcon
            width={6}
            sizeUnit="vw"
            statusA={0}
            lightLv={100}
            disabledConnectStatus
          />
          <Typography variant="caption">light action on press</Typography>
        </DivCol>
        <ArrowForward sx={{ fontSize: "1.2rem" }} />

        <DivExist show={delayT1 > 0 ? true : false}>
          <DivInline
            justifyContent="flex-end"
            style={{
              border: "1px solid #fff",
              padding: 3,
              borderRadius: 6,
              width: "10vw",
            }}
          >
            <DialogInputMinSec
              title="delay time 1"
              initialSec={delayT1}
              iconSize={30}
              showValue
              showValueVariant="body1"
              onInput={async (val) => setDelayT1(val)}
              minSec={5}
              dispatch={dispatch}
            />
          </DivInline>
          <ArrowForward sx={{ fontSize: "1.2rem" }} />
          <DivCol>
            <LightIcon
              width={6}
              sizeUnit="vw"
              statusA={0}
              lightLv={50}
              disabledConnectStatus
            />
            <Typography variant="caption">delay light action</Typography>
          </DivCol>
          <DivExist show={delayT2 > 0 ? true : false}>
            <ArrowForward sx={{ fontSize: "1.2rem" }} />
            <DivInline
              style={{
                border: "1px solid #fff",
                padding: 5,
                borderRadius: 6,
                width: "10vw",
              }}
              justifyContent="flex-end"
            >
              <DialogInputMinSec
                title="delay time 2"
                initialSec={delayT2}
                iconSize={30}
                showValue
                showValueVariant="body1"
                onInput={async (val) => setDelayT2(val)}
                dispatch={dispatch}
              />
            </DivInline>
            <ArrowForward sx={{ fontSize: "1.2rem" }} />
            <DivCol>
              <LightIcon
                width={6}
                sizeUnit="vw"
                statusA={0}
                lightLv={30}
                disabledConnectStatus
              />
              <Typography variant="caption">
                delay light action step 2
              </Typography>
            </DivCol>
          </DivExist>
        </DivExist>
        <DivExist show={delayT1 === 0}>
          <DivInline>
            <Typography>No delayed light action</Typography>
          </DivInline>
        </DivExist>
      </DivInline>
    </ModalSM>
  );
}
