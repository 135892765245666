import { useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box, Button } from "@mui/material";
import DivInline from "components/DivInline";
import { Workspaces } from "@mui/icons-material";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
import { deleteOneDeviceGroupAction, upsertOneDeviceGroupAction } from "actions/gatewayActions";
import { confirmWindow } from "actions/screenActions";
import { dtkCmdIncludeDaliLight, daliMultiLightToSingleLightCommand } from "actions/daliCtlActions";
import ZoneControlIcon from "components/ZoneControlIcon";
import AdminContainer from "components/AdminContainer";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";

const gs = new General();
const modalWidth = 95;
const w = [8, 6, 8, 7, 5, 33, 33];
export default function LightControlledZoneControlModal({ lightObj }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const { zoneControlObjAll, daliCtlObjAll, userObj } = state;
    const { serial, zoneControlArray } = lightObj;
    const lightDaliCtlID = lightObj?.daliCtlID;
    const lightDaliShortAdd = lightObj?.shortAdd;
    const daliCtlObj = daliCtlObjAll[lightDaliCtlID];
    const lightDaliAddIndex = (daliCtlObj?.addList || []).findIndex(a => a === lightDaliShortAdd);
    let modalTitle = `Light ${serial} controlled by zone control`;
    if (lightDaliCtlID) modalTitle += ` (DALI control ID: ${lightDaliCtlID}, shortAdd:${lightDaliShortAdd})`;
    const zoneControlArrayAll = Object.keys(zoneControlObjAll).filter(s => {
        const zoneControlObj = zoneControlObjAll[s];
        if (Object.values(zoneControlObj.serialMap || {}).includes(serial)) return true;
        for (let i = 1; i < 3; i++) {
            if (Object.keys(zoneControlObj?.[`setting${i}`] || {}).includes(serial)) return true;
            if (Object.keys(zoneControlObj?.[`GAS_setting${i}`] || {}).includes(lightDaliCtlID || serial)) {
                if (lightDaliCtlID) {
                    const GAS_setting = zoneControlObj?.[`GAS_setting${i}`]?.[lightDaliCtlID];
                    const command = GAS_setting?.command;
                    const commandIncludeDaliLight = dtkCmdIncludeDaliLight(command, daliCtlObj, lightObj);
                    return commandIncludeDaliLight;
                }
                else {
                    return true;
                }

            }
        }
        return false;
    })
    const handleOpen = () => {
        if (userObj.level > 0) return;
        setOpen(true);
    };
    const handleDeleteGroupAction = async (zoneControlID, settingNum, command) => {
        const gatewayID = lightDaliCtlID ? daliCtlObj.gatewayID : lightObj.gatewayID;
        const deviceID = lightDaliCtlID ? lightDaliCtlID : serial;
        const deviceType = lightDaliCtlID ? "daliCtl" : "light";
        const dtkAdd = lightDaliCtlID ? daliCtlObj.dtkAdd : lightObj.dtkAdd;
        let isLastDaliLight = false;
        const groupID = zoneControlID.replace("zc_", "");
        const json = gs.dtKStrToJson(command);
        if (lightDaliCtlID) {
            let lightLvAry = json?.lightLvAry;
            lightLvAry = lightLvAry.filter(lv => lv >= 0 && lv <= 100);
            if (lightLvAry?.length === 1) isLastDaliLight = true;
        }

        confirmWindow(dispatch, `Delete group action of zoneControl ${zoneControlID} setting ${settingNum} inside ${deviceType} ${deviceID} - ${command} ?`, async () => {
            if (isLastDaliLight || !lightDaliCtlID) {
                console.log("delete group action");
                deleteOneDeviceGroupAction(gatewayID, groupID, deviceID, deviceType, dtkAdd, [settingNum]);
            } else {
                let newJson = { ...json };
                if ("lightLvAry" in newJson) newJson.lightLvAry[lightDaliAddIndex] = 101;
                if ("D1LightLvAry" in newJson) newJson.D1LightLvAry[lightDaliAddIndex] = 101;
                if ("D2LightLvAry" in newJson) newJson.D2LightLvAry[lightDaliAddIndex] = 101;
                const newCommand = gs.jsonCommandToDtk(newJson);
                upsertOneDeviceGroupAction(gatewayID, groupID, deviceID, deviceType, dtkAdd, [settingNum], [newCommand]);
            }
        });
    }
    return (

        <>
            {
                (zoneControlArray || []).length === 0 ?
                    <Button onClick={handleOpen} variant="outlined" size="small">No zone control</Button>
                    :
                    <DivInline onClick={handleOpen}>
                        {
                            (zoneControlArray || []).map((s, key) => {
                                const zcObj = zoneControlObjAll[s];
                                return (
                                    <Box key={key} sx={{ margin: "0 0.3vw" }}>
                                        <ZoneControlIcon
                                            width={2}
                                            sizeUnit="vw"
                                            type={zcObj?.type}
                                            tooltip={s}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </DivInline>
            }
            <AdminContainer>
                <ModalSM modalTitle={modalTitle}
                    open={open}
                    onClose={() => setOpen(false)}
                    height="80vh"
                    width="90vw"
                    disableBottomClose
                >
                    <Box sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                        <Typography variant="caption" sx={{ width: `${w[0]}%` }}>zoneControlID</Typography>
                        <Typography variant="caption" sx={{ width: `${w[1]}%` }}>Type</Typography>
                        <Typography variant="caption" sx={{ width: `${w[2]}%` }}>Under light zoneControl Array</Typography>
                        <Typography variant="caption" sx={{ width: `${w[3]}%` }}>In serialMap</Typography>
                        <Typography variant="caption" sx={{ width: `${w[4]}%` }}>Group action</Typography>
                        <Typography variant="caption" sx={{ width: `${w[5]}%` }}>In zoneControl settings</Typography>
                        <Typography variant="caption" sx={{ width: `${w[6]}%` }}>GAS command</Typography>
                    </Box>
                    {
                        zoneControlArrayAll.map((s, key) => {
                            const zcObj = zoneControlObjAll[s];
                            let dbSetting = [], gasCmdArray = [], isLastDaliLightArray = [];
                            for (let i = 1; i < 13; i++) {
                                const setting = zcObj?.[`setting${i}`];
                                if (setting) {
                                    const serialSetting = setting[serial];
                                    if (serialSetting && !gs.isEmptyJson(serialSetting))
                                        if (Object.keys(setting || {}).includes(serial)) {
                                            dbSetting.push(i);
                                        }
                                }
                                const GAS_setting = zcObj?.[`GAS_setting${i}`]?.[lightDaliCtlID || serial];
                                if (GAS_setting && !gs.isEmptyJson(GAS_setting)) {
                                    let command = GAS_setting?.command;
                                    let confirmed = false;
                                    const cmdJson = gs.dtKStrToJson(command);
                                    let cmdObj;
                                    let isLastDaliLight = false;
                                    if (lightDaliCtlID) {
                                        const cmdIncludeDaliLight = dtkCmdIncludeDaliLight(command, daliCtlObj, lightObj);
                                        const lightLv = cmdJson?.lightLvAry?.[lightDaliAddIndex];
                                        const D1LightLv = cmdJson?.D1LightLvAry?.[lightDaliAddIndex] ?? "-";
                                        const D2LightLv = cmdJson?.D2LightLvAry?.[lightDaliAddIndex] ?? "-";
                                        const commandCode = cmdJson?.commandCode?.toString(16).padStart(4, "0") ?? "-";
                                        cmdObj = { settingNum: i, commandCode, lightLv, D1LightLv, D2LightLv, command };
                                        if ((cmdJson?.lightLvAry || [])?.length === 1) isLastDaliLight = true;
                                        confirmed = GAS_setting?.confirmed && cmdIncludeDaliLight;
                                    } else {
                                        confirmed = GAS_setting?.confirmed;
                                        let cmdMsg = gs.dtkActionCmdToMsg(cmdJson);
                                        cmdObj = { settingNum: i, cmdMsg, command, commandCode: cmdJson?.commandCode };
                                    }
                                    gasCmdArray.push(cmdObj);
                                    isLastDaliLightArray.push(isLastDaliLight);
                                }
                            }

                            return (
                                <Box key={key} sx={{ display: 'flex', borderBottom: "1px solid lightgrey" }}>
                                    <Typography variant="caption" sx={{ width: `${w[0]}%` }}> {s} </Typography>
                                    <Box sx={{ width: `${w[1]}%` }}>
                                        <ZoneControlIcon
                                            width={2}
                                            sizeUnit="vw"
                                            type={zcObj?.type}
                                        />
                                    </Box>
                                    <Typography sx={{ width: `${w[2]}%` }}>{(lightObj.zoneControlArray || []).includes(s) ? `\u{2705}` : `\u{274c}`}</Typography>
                                    <Typography sx={{ width: `${w[3]}%` }}>{Object.values(zcObj.serialMap || {}).includes(serial) ? `\u{2705}` : `\u{274c}`}</Typography>
                                    <Typography variant="caption" sx={{ width: `${w[4]}%` }}>{zcObj.enableGroupAction ? `Enabled` : `Disabled`}</Typography>
                                    <Box sx={{ width: `${w[5]}%` }}>
                                        {
                                            (dbSetting).map((settingNum) => {
                                                const serialSetting = zcObj?.[`setting${settingNum}`]?.[serial];
                                                return (
                                                    <Box key={settingNum}>
                                                        <Typography display="block" variant="caption">{`Setting${settingNum}`}</Typography>
                                                        <Typography display="block" variant="caption">{gs.dtkActionCmdToMsg(serialSetting)}</Typography>
                                                        <Typography display="block" variant="caption">{`Cmd:${gs.jsonCommandToDtk(serialSetting)}`}</Typography>

                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                                    <Box sx={{ width: `${w[6]}%` }}>
                                        {
                                            gasCmdArray.map((cmdObj, k) => {
                                                let msg = `Setting${cmdObj.settingNum}-Code:${cmdObj.commandCode}`;
                                                let settingObj;
                                                if (lightDaliCtlID) {
                                                    const settingObjMulti = gs.dtKStrToJson(cmdObj.command);
                                                    settingObj = daliMultiLightToSingleLightCommand(settingObjMulti, daliCtlObj, lightObj.shortAdd)

                                                } else {
                                                    settingObj = gs.dtKStrToJson(cmdObj.command);
                                                }
                                                return (
                                                    <Box key={k} >
                                                        <DivInline>
                                                            <Typography variant="caption">{`Setting${cmdObj.settingNum}`}</Typography>
                                                            <GroupActionConfirmOneDevice deviceObj={zcObj} controlledDeviceObj={lightObj} settingNumArray={[cmdObj.settingNum]} />
                                                            <IconButtonDelete size="small" onBtnClick={async () => await handleDeleteGroupAction(s, cmdObj.settingNum, cmdObj.command)} />
                                                        </DivInline>
                                                        <Typography display="block" variant="caption">{gs.dtkActionCmdToMsg(settingObj)}</Typography>
                                                        <Typography display="block" variant="caption">{`Cmd:${gs.jsonCommandToDtk(settingObj)}`}</Typography>
                                                    </Box>

                                                )
                                            })}
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </ModalSM >
            </AdminContainer>
        </>
    )

}