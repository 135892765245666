import { useState, useContext, useMemo } from 'react';
import { SMContext } from "context/smContext";
import { Button, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import ModalSM from 'components/ModalSM';
import DivCol from 'components/DivCol';
import DivInline from 'components/DivInline';
import Divider10 from 'components/Divider10';
import { confirmWindow, openSnackbar } from 'actions/screenActions';
import { uploadMultiDevicesToFS } from 'actions/serverActions';
import { mgFindInArray } from 'actions/mongoApiActions';
import General from '@ecoenghk/general';
const gs = new General();

export default function TransferMapDevicesToFs({ mapObj }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const [sensorObjAll, setSensorObjAll] = useState({});
    const [zoneControlObjAll, setZoneControlObjAll] = useState({});
    const { mapID, lightObj, sensorObj, gatewayObj, daliCtlObj, zoneControlObj } = mapObj;
    const handleOpen = async () => {
        const dataS = await mgFindInArray("sensor", "mapID", mapID);
        let objAllS = {};
        (dataS || []).forEach((obj) => {
            const sensorID = obj.sensorID;
            objAllS[sensorID] = obj;
        });
        setSensorObjAll(objAllS);
        const dataZC = await mgFindInArray("zoneControl", "mapID", mapID);
        let objAllZC = {};
        (dataZC || []).forEach((obj) => {
            const zoneControlID = obj.zoneControlID;
            objAllZC[zoneControlID] = obj;
        });
        setZoneControlObjAll(objAllZC);
        setOpen(true);
    }
    const timeTableIDArray = useMemo(() => {
        let arr = [];
        Object.keys(zoneControlObj || {}).forEach(zcID => {
            const zcObj = zoneControlObjAll[zcID];
            if (zcObj?.type === 'timer') {
                (zcObj?.timeTableID1 || []).forEach(tid => {
                    if (!arr.includes(tid)) arr.push(tid);
                });
                (zcObj?.timeTableID2 || []).forEach(tid => {
                    if (!arr.includes(tid)) arr.push(tid);
                });
            }
        });
        return arr;
    }, [zoneControlObjAll, zoneControlObj]);
    const handleTransfer = async () => {
        confirmWindow(dispatch, 'Confirm transfer the devices to firestore?', async () => {
            await uploadMultiDevicesToFS("map", "SM_map", "mapID", [mapID]);
            await gs.waitFor(1000);
            await uploadMultiDevicesToFS("serial", "SM_serial", "serial", Object.keys(lightObj || {}));
            await gs.waitFor(1000);
            await uploadMultiDevicesToFS("sensor", "SM_sensor", "sensorID", Object.keys(sensorObj || {}));
            await gs.waitFor(1000);
            await uploadMultiDevicesToFS("gateway", "SM_gateway", "gatewayID", Object.keys(gatewayObj || {}));
            await gs.waitFor(1000);
            await uploadMultiDevicesToFS("daliCtl", "SM_daliCtl", "daliCtlID", Object.keys(daliCtlObj || {}));
            await gs.waitFor(1000);
            await uploadMultiDevicesToFS("zoneControl", "SM_zoneControl", "zoneControlID", Object.keys(zoneControlObj || {}));
            await gs.waitFor(1000);
            await uploadMultiDevicesToFS("timeTable", "SM_timeTable", "timeTableID", timeTableIDArray);
            await gs.waitFor(1000);
            openSnackbar(dispatch, "Devices transferred to firestore");
        })
    }

    return (
        <>
            <button onClick={handleOpen}> Server▶︎FS</button>
            {
                open &&
                <ModalSM
                    open={open}
                    onClose={() => setOpen(false)}
                    modalTitle={`Transfer Map ${mapID} Devices to Other Server`}
                    width="80vw"
                    height="90vh"
                >
                    <Typography onClick={() => console.log(state)}>The following will be updated to firestore: </Typography>
                    <Divider10 />
                    <DivInline justifyContent='space-between' alignItems='flex-start'>
                        <DivCol justifyContent='flex-start' >
                            <Typography>Lights [{Object.keys(lightObj || {}).length}]</Typography>
                            <Divider10 />
                            {
                                Object.keys(lightObj).map((s, key) => {
                                    return (
                                        <Typography variant="caption" key={key}>{s}</Typography>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' >
                            <Typography>Sensors [{Object.keys(sensorObj || {}).length}]</Typography>
                            <Divider10 />
                            {
                                Object.keys(sensorObj).map((s, key) => {
                                    const type = sensorObjAll[s]?.type
                                    return (
                                        <Typography variant="caption" key={key}>{`${s}[${type}]`}</Typography>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' >
                            <Typography>Gateways [{Object.keys(gatewayObj || {}).length}]</Typography>
                            <Divider10 />
                            {
                                Object.keys(gatewayObj).map((s, key) => {
                                    return (
                                        <Typography variant="caption" key={key}>{s}</Typography>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' >
                            <Typography>Dali Controllers [{Object.keys(daliCtlObj || {}).length}]</Typography>
                            <Divider10 />
                            {
                                Object.keys(daliCtlObj).map((s, key) => {
                                    return (
                                        <Typography variant="caption" key={key}>{s}</Typography>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' >
                            <Typography>Zone Control [{Object.keys(zoneControlObj || {}).length}]</Typography>
                            <Divider10 />
                            {
                                Object.keys(zoneControlObj).map((s, key) => {
                                    const type = zoneControlObjAll[s]?.type;
                                    return (
                                        <Typography variant="caption" key={key}>{`${s}[${type}]`}</Typography>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start'>
                            <Typography>Time table [{timeTableIDArray.length}]</Typography>
                            <Divider10 />
                            {
                                timeTableIDArray.map((s, key) => {
                                    return (
                                        <Typography variant="caption" key={key}>{s}</Typography>
                                    )
                                })
                            }
                        </DivCol>
                    </DivInline>
                    <Divider10 />
                    <Button variant="contained" color="primary" endIcon={<ArrowForwardIos />}
                        onClick={handleTransfer}>
                        Transfer to firestore
                    </Button>
                </ModalSM>
            }


        </>
    )
}