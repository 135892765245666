
import {
  mgFindAll,
  mgFindByMatchAndRange,
  mgPost,
  mgUpdateOneUpsert,
} from "./mongoApiActions";
import { openSnackbar } from "./screenActions";
import {
  fetchOneGateway,
  // gatewayDisconnectLights,
  serverUpdateToGateway,
} from "./gatewayActions";
import { fetchOneLight } from "./lightActions";
// import { fetchOneSensor } from "./sensorActions";
import General from "@ecoenghk/general";
import { fetchWithTimeout } from "./generalActions";
import moment from "moment";
const gs = new General();

export const changeServerUploadFirestore = async (serverID, newStatus) => {
  const payload = { serverID, newStatus };
  const res = await mgPost("changeUploadFS", payload);
  console.log(res);
};
// export const enableUploadFirestore = async (
//   dispatch,
//   serverID,
//   serverObj,
//   status
// ) => {
//   const resMg = await mgUpdateOneUpsert(
//     "server",
//     { serverID: serverID },
//     { enableUploadFS: Boolean(status) }
//   );
//   console.log({ resMg });
//   await gs.waitFor(300);
//   await uploadServerFS(serverID);

//   openSnackbar(
//     dispatch,
//     status ? "Enabled upload to cloud" : "Disabled upload to cloud",
//     "success",
//     3000
//   );
// };

// export const serverConnectLight = async (
//   dispatch,
//   serverID,
//   serial,
//   enableUploadFS
// ) => {
//   await mgUpdateOneUpsert("serial", { serial }, { serverID });
//   await gs.waitFor(800);
//   await fetchOneLight(dispatch, serial);
//   if (enableUploadFS) {
//     await uploadSerialFS(serial);
//     await uploadServerFS(serverID);
//   }
// };
// export const serverDisconnectLight = async (
//   dispatch,
//   socket,
//   serverID,
//   serial,
//   lightObj,
//   enableUploadFS
// ) => {
//   // await mgUpdateOneUpsert("serial", { serial }, { serverID: "" });
//   // await gatewayDisconnectLights(socket, [lightObj]);
//   // if (enableUploadFS) await uploadServerFS(serverID);
// };
export const rebootServer = async (delaySecond) => {
  const payload = {
    type: "rebootServer",
    delaySecond: delaySecond || 3,
  };
  global.socket.emit("fromWeb", payload);
};
export const updateServerDataFromFB = async (socket, updateType, deviceID) => {
  let payload = {
    type: "updateServerDataFromFB",
    updateType,
  };
  if (updateType === "singleGateway") {
    payload = { ...payload, gatewayID: deviceID };
  } else if (updateType === "singleSerial") {
    payload = { ...payload, serial: deviceID };
  } else if (updateType === "singleSensor") {
    payload = { ...payload, sensorID: deviceID };
  } else if (updateType === "singleMap") {
    payload = { ...payload, mapID: deviceID };
  } else if (updateType === "serialSchedules") {
    payload = { ...payload, serial: deviceID };
  } else if (updateType === "singleUser") {
    payload = { ...payload, uid: deviceID };
  }
  console.log(payload);
  socket.emit("fromWeb", payload);
};
// export const serverConnectGateway = async (
//   dispatch,
//   socket,
//   serverID,
//   gatewayID,
//   enableUploadFS
// ) => {
//   await mgUpdateOneUpsert("gateway", { gatewayID }, { serverID });
//   await gs.waitFor(800);
//   await fetchOneGateway(dispatch, gatewayID);
//   await serverUpdateToGateway(socket, gatewayID, "gateway");
//   if (enableUploadFS) {
//     await uploadGatewayFS(gatewayID);
//     await uploadServerFS(serverID);
//   }
// };
// export const serverDisconnectGateway = async (
//   dispatch,
//   socket,
//   gatewayID,
//   enableUploadFS
// ) => {
//   await mgUpdateOneUpsert("gateway", { gatewayID }, { serverID: "" });
//   await gs.waitFor(800);
//   await fetchOneGateway(dispatch, gatewayID);
//   await serverUpdateToGateway(socket, gatewayID, "gateway");
//   if (enableUploadFS) {
//     await uploadGatewayFS(gatewayID);
//   }
// };
// export const editServerProps = async (
//   dispatch,
//   serverID,
//   enableUploadFS,
//   updateObj
// ) => {
//   const resMg = await mgUpdateOneUpsert(
//     "server",
//     { serverID: serverID },
//     updateObj
//   );
//   console.log({ resMg });
//   if (enableUploadFS) {
//     await gs.waitFor(300);
//     await uploadServerFS(serverID);
//   }
//   dispatch({
//     type: "UPDATED_SERVER_PROPERTY",
//     payload: updateObj,
//   });
// };
export const updateServerProperty = async (serverID, updateObj) => {
  const payload = {
    type: "updateServerProperty",
    serverID,
    updateObj
  }
  global.socket.emit("fromWeb", payload);
  console.log(`[COMMAND OUT] updateServerProperty serverID:${serverID} updateObj:${JSON.stringify(updateObj)}`, payload);
}
export const serverConnectSensor = async (socket, sensorID) => {
  const jsonData = {
    sensorID,
    type: "serverConnectSensor",
  };
  console.log(`[SOCKET OUT] serverConnectSensor ${sensorID}`);
  socket.emit("fromWeb", jsonData);
};
export const serverDisconnectSensor = async (socket, sensorID, sensorObj) => {
  const jsonData = {
    sensorID,
    sensorObj,
    type: "serverDisconnectSensor",
  };
  console.log(`[SOCKET OUT] serverDisconnectSensor ${sensorID}`);
  socket.emit("fromWeb", jsonData);
};
export const getLatestServerFirmwareVer = async (socket) => {
  const payload = {
    type: "getLatestServerFirmwareVer",
  };
  socket.emit("fromWeb", payload);
};
export const getLatestServerFirmwareVerByApi = async (localserverUrl) => {
  const url = `${localserverUrl}/api/getLatestServerFirmwareVer`;
  console.log(`[API] getLatestServerFirmwareVerByApi ${url}`);
  try {
    // const res = await fetchWithTimeout(url, {
    //   timeout: 2000,
    // });
    const res = await fetch(url);
    const response = await res.json();
    const { latestVer, currentVer, toUpdate } = response;
    console.log({ latestVer, currentVer, toUpdate });
    return response;
  } catch (e) {
    console.log("Fail to check latest server firmware");
    return { latestVer: "", currentVer: "", toUpdate: false };
  }
};
export const updateServerFirmware = async () => {
  const payload = {
    type: "updateServerFirmware",
  };
  global.socket.emit("fromWeb", payload);
};
export const updateServerFirmwareByApi = async (localserverUrl) => {
  const url = `${localserverUrl}/api/updateServerFirmware`;
  console.log(`[API] updateServerFirmwareByApi ${url}`);
  try {
    const res = await fetch(url);
    const response = await res.json();
    console.log({ response });
    return response;
  } catch (e) {
    console.log("Fail to update server firmware");
    return { result: "FAIL" };
  }
};
export const updateSeverWebapp = async () => {
  const payload = {
    type: "downloadWebapp",
  };
  global.socket.emit("fromWeb", payload);
};
export const restartServerProgram = async () => {
  const payload = {
    type: "restartServerProgram",
  };
  global.socket.emit("fromWeb", payload);
};
export const fetchServerLog = async (lowerTsp, upperTsp) => {
  const arr = await mgFindByMatchAndRange(
    "serLog",
    "",
    "",
    "timeStamp",
    upperTsp,
    lowerTsp
  );
  return arr;
};
export const fetchServerConsoleLog = async (localserverUrl, lines) => {
  const url = `${localserverUrl}/api/fetchPm2Logs?lines=${lines}`;
  console.log(`[API] fetchPm2Logs ${url}`);
  try {
    const res = await fetch(url);
    const response = await res.json();
    console.log({ response });
    return response;
  } catch (e) {
    console.log("Fail fetchPm2Logs");
    return { result: "FAIL" };
  }
};
export const searchServerConsoleLog = async (localserverUrl, searchStr) => {
  const url = `${localserverUrl}/api/searchPm2Logs?keyword=${searchStr}`;
  console.log(`[API] searchPm2Logs ${url}`);
  try {
    const res = await fetch(url);
    const response = await res.json();
    console.log({ response });
    return response;
  } catch (e) {
    console.log("Fail searchPm2Logs");
    return { result: "FAIL" };
  }
};
export const setServerConsoleLogFilter = async (localserverUrl, filterStr) => {
  const url = `${localserverUrl}/api/set_config_logfilter_description?filter_description=${filterStr}`;
  console.log(`[API] setServerConsoleLogFilter ${url}`);
  try {
    const res = await fetch(url);
    const response = await res.json();
    console.log({ response });
    return response;
  } catch (e) {
    console.log("Fail setServerConsoleLogFilter");
    return { result: "FAIL" };
  }
};
export const clearServerConsoleLogFilter = async (localserverUrl) => {
  const url = `${localserverUrl}/api/clear_config_logfilter_description`;
  console.log(`[API] clearServerConsoleLogFilter ${url}`);
  try {
    const res = await fetch(url);
    const response = await res.json();
    console.log({ response });
    return response;
  } catch (e) {
    console.log("Fail clearServerConsoleLogFilter");
    return { result: "FAIL" };
  }
};
export const checkServerSocket = async () => {
  const payload = {
    type: "checkServerSocket",
  };
  global.socket.emit("fromWeb", payload);
};
export const getLatestDeviceFirmwareVer = async (deviceObj) => {
  const payload = {
    type: "getDeviceFirmwareVer",
    deviceObj,
  };
  global.socket.emit("fromWeb", payload);
}
export const sendAwsEmail = async (serverIp, emailTitle, toAddressesArray, body_html, successMsg, failMsg) => {
  const url = `${serverIp}/api/setDocFS`;
  const tsp = moment().valueOf();
  const body = {
    docPath: `AWS_email/${tsp.toString()}`,
    setObj: {
      emailTitle,
      sender: "Remomo Monitor <inquiry@ecotech.com.hk>",
      timeStamp: tsp,
      timeString: moment().format("YYYY-MM-DD HH:mm:ss"),
      // serial: "skymon",
      toAddresses: toAddressesArray,
      body_html,
    },
  };
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
    });
    if (response.status === 200) {
      const res = await response.json();
      console.log(res);
      alert(successMsg || `Email sent`);
    }
  } catch (e) {
    alert(failMsg || `Email sent fail`);
  }
}
export const uploadMultiDevicesToFS = async (mgCol, fsCollPath, deviceIdField, deviceIDArray) => {
  if (deviceIDArray.length === 0) return;
  const payload = {
    type: "mgMultiDocToFs",
    mgCol,
    fsCollPath,
    deviceIdField,
    deviceIDArray
  };
  global.socket.emit("fromWeb", payload);
}