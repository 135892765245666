import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import ModalSM from "components/ModalSM";
import { Box, IconButton, Typography, Tooltip } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp, KeyboardDoubleArrowDown, KeyboardDoubleArrowUp, Map, Visibility, VisibilityOff } from "@mui/icons-material";
import MapPanel from "./MapPanel";
import DivCol from "components/DivCol";
import DivInline from "components/DivInline";
import IconButtonDelete from "components/IconButtonDelete";
import { confirmWindow } from "actions/screenActions";
import { removeMap } from "actions/mapActions";
import { updateUserProperty } from "actions/userActions";
import IconButtonSave from "components/IconButtonSave";

export default function MapManagementModal() {
    const [state, dispatch] = useContext(SMContext);
    const { mapObjAll, userObj } = state;
    const [open, setOpen] = useState(false);
    const [mapArray, setMapArray] = useState([]);
    const [hideMapArray, setHideMapArray] = useState([]);
    const handleOpen = async () => {
        setOpen(true);
        const userMapQty = userObj.mapArray?.length;
        const mapObjAllQty = Object.keys(mapObjAll || {})?.length;
        if (userMapQty > 0 && userMapQty === mapObjAllQty) {
            setMapArray(userObj.mapArray);
        } else {
            const m = Object.keys(mapObjAll || {});
            setMapArray(m);
        }
        setHideMapArray(userObj.hideMapArray || []);
    }
    const handleSave = async () => {
        console.log(mapArray, hideMapArray);
        await updateUserProperty(userObj, { mapArray, hideMapArray });
        setOpen(false);
    }
    const handleRemoveMap = async (mid) => {
        const obj = mapObjAll[mid];
        confirmWindow(dispatch, "Confirm remove this map?", async () => {
            await removeMap(userObj, obj);
        })
    }
    return (
        <>
            <IconButton onClick={handleOpen} size="large">
                <Tooltip placement="top" title={<Typography>Map management</Typography>}>
                    <Map style={{ fontSize: "1.2em" }} />
                </Tooltip>
                <ModalSM
                    open={open}
                    onClose={() => setOpen(false)}
                    width="95vw"
                    height="97vh"
                    onSave={handleSave}
                    modalTitle="Map Management"
                    titleComponent={
                        <IconButtonSave onBtnClick={handleSave} />
                    }
                >
                    <DivCol>
                        {
                            (mapArray || []).map((mapID, key) => {
                                const mapObj = mapObjAll[mapID];
                                const { mapName, mapUrl, mapFileName } = mapObj || {};
                                let bgUrl = mapFileName ? `${global.ip}/img/${mapFileName}` : mapUrl;
                                if (state.enableFS) bgUrl = mapUrl;
                                const hid = (hideMapArray || []).includes(mapID);
                                return (
                                    <DivInline key={key} sx={{ margin: "0.5vw" }}>
                                        <div style={{ width: "8vw", display: "flex", flexWrap: "wrap" }}>
                                            <IconButton
                                                sx={{ width: "3vw" }}
                                                disabled={key === 0}
                                                onClick={() => {
                                                    let newArr = [...mapArray];
                                                    newArr[key - 1] = mapArray[key];
                                                    newArr[key] = mapArray[key - 1];
                                                    setMapArray(newArr);
                                                }}>
                                                <KeyboardArrowUp />
                                            </IconButton>
                                            <IconButton
                                                sx={{ width: "3vw" }}
                                                disabled={key === mapArray.length - 1}
                                                onClick={() => {
                                                    let newArr = [...mapArray];
                                                    newArr[key + 1] = mapArray[key];
                                                    newArr[key] = mapArray[key + 1];
                                                    setMapArray(newArr);
                                                }}>
                                                <KeyboardArrowDown />
                                            </IconButton>
                                            <IconButton
                                                sx={{ width: "3vw" }}
                                                disabled={key === 0}
                                                onClick={() => {

                                                    const first = mapArray[key];
                                                    let newArr = [...mapArray].filter(m => m !== first);
                                                    newArr.unshift(first);
                                                    setMapArray(newArr);
                                                }}>
                                                <KeyboardDoubleArrowUp />
                                            </IconButton>
                                            <IconButton
                                                size="small"
                                                sx={{ width: "3vw" }}
                                                disabled={key === mapArray.length - 1}
                                                onClick={() => {
                                                    const last = mapArray[key];
                                                    let newArr = [...mapArray].filter(m => m !== last);
                                                    newArr.push(last);
                                                    setMapArray(newArr);
                                                }
                                                }>
                                                <KeyboardDoubleArrowDown />
                                            </IconButton>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "flex-end",
                                                backgroundSize: "cover",
                                                height: "12vh",
                                                width: "70vw",
                                                backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7) ), url(${bgUrl})`,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "cover",
                                                padding: 10,
                                                color: "white",
                                            }}
                                        >
                                            <DivCol alignItems="flex-end">
                                                <Typography sx={{ color: hid ? "#AAB7B8" : "#F7DC6F" }}>{mapName}</Typography>
                                                <Typography sx={{ color: hid ? "#AAB7B8" : "#fff" }} variant="caption">{mapID}</Typography>
                                            </DivCol>
                                            <IconButtonDelete onBtnClick={async () => await handleRemoveMap(mapID)} />
                                            <IconButton onClick={() => {
                                                if (!hid) return;
                                                let newArr = (hideMapArray || []).filter(m => m !== mapID);
                                                setHideMapArray(newArr);
                                            }}>
                                                <Visibility color={hid ? "#fff" : "primary"} />
                                            </IconButton>
                                            <IconButton onClick={() => {
                                                if (hid) return;
                                                let newArr = [...(hideMapArray || []), mapID];
                                                setHideMapArray(newArr);
                                            }}>
                                                <VisibilityOff color={hid ? "primary" : "#fff"} />
                                            </IconButton>

                                        </div>
                                    </DivInline>
                                )
                            })
                        }
                    </DivCol>
                </ModalSM>
            </IconButton>
        </>
    )
}


