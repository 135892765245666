import { Typography } from "@mui/material";
import DivInline from "./DivInline";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";

export default function ListSortButton({ title, field, sortBy, sortOrder, onAsc, onDesc, arrowFontSize = "1.2rem" }) {
    return (
        <DivInline>
            <Typography>{title}</Typography>
            <ArrowUpward
                sx={{
                    fontSize: arrowFontSize,
                    cursor: "pointer",
                    "&:hover": { color: "gold" },
                    color:
                        sortOrder === "asc" && field === sortBy ? "red" : "",
                }}
                onClick={() => onAsc(field)}
            />
            <ArrowDownward
                sx={{
                    fontSize: arrowFontSize,
                    cursor: "pointer",
                    "&:hover": { color: "gold" },
                    color:
                        sortOrder === "desc" && field === sortBy ? "red" : "",
                }}
                onClick={() => onDesc(field)}
            />
        </DivInline>
    )
}