import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Box, Typography } from "@mui/material";
import DaliCtlIcon from "components/DaliCtlIcon";
import { deviceConnected, gatewayConnected } from "actions/generalActions";
import moment from "moment";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryDaliCtlOneLightSetting } from "actions/daliCtlActions";
export default function DaliCtlStatusPanel() {
    const [state] = useContext(SMContext);
    const {
        daliCtlObjAll,
        activeDaliCtlID,
        gatewayObjAll
    } = state;
    const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
    const gwConnected = gatewayConnected(gatewayObjAll[daliCtlObj?.gatewayID]);
    const connected = deviceConnected(daliCtlObj?.zigbeeConnected, daliCtlObj?.timeStamp, gwConnected);
    return (
        <>
            <Box sx={{ height: "20vh", width: "100%" }}>
                <Typography variant="h6" display="block" align="center">Status</Typography>
                <Divider10 />
                <DivInline justifyContent="center">
                    <DaliCtlIcon daliCtlObj={daliCtlObj} gatewayConnected={connected} width={8} sizeUnit="vh" />
                </DivInline>
                <DivInline>
                    <Typography variant="caption">Bypass {daliCtlObj?.bypass ? "Yes" : "-"}</Typography>
                    <IconButtonRefresh onBtnClick={() => queryDaliCtlOneLightSetting(daliCtlObj, 0x0b23)} />
                </DivInline>
                <Typography variant="caption" align="right" display="block">{moment(daliCtlObj?.timeStamp).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </Box>

        </>
    )
}