import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import { titleText, dataText } from "asset/string/color";
import ZoneControlIcon from "components/ZoneControlIcon";
import ThemeContainer from "components/ThemeContainer";
import DivCol from "components/DivCol";
import DivInline from "components/DivInline";
import DivExist from "components/DivExist";
import { zoneCtlDefault } from "asset/string/string";
import { Lock, Repeat } from "@mui/icons-material";
import General from "@ecoenghk/general";
import { fetchOneZc, updateSingleZcFsToLs } from "actions/zcActions";
import LockIcon from "components/LockIcon";
import DeviceMongoModal from "components/DeviceMongoModal";
import { secToMin } from "actions/generalActions";
const gs = new General();

export default function SelectedZcInfo() {
  const [state, dispatch] = useContext(SMContext);

  const { selectedID, zoneControlObjAll } = state;
  const zoneControlObj = zoneControlObjAll[selectedID] || {};
  const {
    zoneControlID,
    zoneControlName,
    type,
    setting1,
    lockLevelSet1,
    lockLevelSet2,
    releaseOnDelayEnd1,
  } = zoneControlObj || {};
  const serialArray = Object.keys(setting1 || {});
  const nameLen = zoneControlName?.length || 0;
  const unknownDevice =
    Object.keys(zoneControlObj || {}).length === 0 ? true : false;
  const handleAddToServer = async () => {
    await updateSingleZcFsToLs(selectedID);
    await gs.waitFor(1200);
    await fetchOneZc(dispatch, selectedID);
    dispatch({
      type: "ALL_UNSELECTED",
    });
  };
  const delayLockStr = (btnNo) => {
    let noI = btnNo * 2 - 1;
    if (btnNo === 1) noI = "";
    const noII = btnNo * 2;
    const lockLv = zoneControlObj[`lockLevelSet${btnNo}`];
    let str = `\u{01f512}${lockLv || 5}`;
    if (zoneControlObj[`delaySec${noI}`] > 0) str += `\u{0021e8}${secToMin(zoneControlObj[`delaySec${noI}`])}`;
    if (zoneControlObj[`delaySec${noII}`] > 0) str += `\u{0021e8}${secToMin(zoneControlObj[`delaySec${noII}`])}`;
    str += `\u{0021e8}`;
    str += zoneControlObj[`releaseOnDelayEnd${btnNo}`] ? `\u{01f513}5` : `\u{01f512}${lockLv}`;
    return str;
  }
  return (
    <>
      <DivInline justifyContent="center">
        <DeviceMongoModal deviceObj={zoneControlObj} >
          <ZoneControlIcon
            width={7}
            sizeUnit="vw"
            type={type}
            plateColor={zoneControlObj?.plateColor || "#cccccc"}
            status={zoneControlObj?.activeSetting === 2 ? 1 : 0}
          />
        </DeviceMongoModal>
      </DivInline>
      <DivExist show={!unknownDevice}>
        <DataSet title="Zone Control ID" data={`${zoneControlID}`} />
        <Typography
          style={{ color: dataText }}
          variant={nameLen > 15 ? "caption" : "body1"}
        >
          {zoneControlName}
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
        <DataSet title="Control type" data={zoneCtlDefault?.[type]?.typeName} />

        <DivExist show={type === "zcSwitch"}>
          <Typography variant="caption" style={{ color: titleText }}>
            Priority setting
          </Typography>
          <DivInline>
            <ZoneControlIcon
              width={2}
              sizeUnit="vw"
              type="zcSwitch"
              status={1}
            />
            <LockIcon lockLevel={lockLevelSet2 || 5} size="1.2em" />
            <Repeat />
            <ZoneControlIcon
              width={2}
              sizeUnit="vw"
              type="zcSwitch"
              status={0}
            />
            <LockIcon
              lockLevel={releaseOnDelayEnd1 === 1 ? 5 : lockLevelSet2}
              size="1.2em"
            />
          </DivInline>
          <hr color="lightgrey" size={1} width="90%" />
        </DivExist>

        <DataSet
          title={`Delay time / priority level`}
          data={delayLockStr(1)}
          hide={type !== "zcSceneButton"}
        />
        {/* <DataSet
            title="Priority level"
            data={
              <>
                <Lock sx={{ fontSize: "1rem" }} />
                {lockLevelSet1 || 5}
              </>
            }
          /> */}
        {/* <DataSet
            title="Priority level after light action"
            data={
              <>
                <Lock sx={{ fontSize: "1rem" }} />
                {releaseOnDelayEnd1 ? 5 : lockLevelSet1}
              </>
            }
          /> */}
        <DivExist show={type === "timer"}>
          <DataSet
            title="Priority level on start"
            data={
              <>
                <Lock sx={{ fontSize: "1rem" }} />
                {lockLevelSet2 || 5}
              </>
            }
          />
          <DataSet
            title="Priority level on end"
            data={
              <>
                <Lock sx={{ fontSize: "1rem" }} />
                {lockLevelSet1 || 5}
              </>
            }
          />
        </DivExist>
      </DivExist>
      <DataSet title="Lights Connected" data={serialArray.length || 0} />
      <DivExist show={zoneControlObj.enableGroupAction ? true : false}>
        <DataSet title="Group action" data="Enabled" />
      </DivExist>
      <DivExist show={unknownDevice}>
        <InfoDivider />
        <Typography sx={{ color: dataText }} align="center">
          Unknown timer/vitural button
        </Typography>
        <Typography sx={{ color: dataText }} align="center">{selectedID}</Typography>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={handleAddToServer}
        >
          Add to this server
        </Button>
      </DivExist>
    </>
  );
}

function DataSet({ title, data, hide }) {
  if (hide) return null;
  return (
    <DivCol alignItems="center" sx={{ width: "100%" }}>
      <Typography variant="caption" align="center" style={{ color: titleText }}>
        {title}
      </Typography>
      <Typography
        sx={{ color: dataText, width: "100%" }}
        align="center"
        variant="caption"
      >
        {data}
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
    </DivCol>
  );
}
function InfoDivider() {
  return <hr color="lightgrey" size={1} width="90%" />;
}
