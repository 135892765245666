import { Fragment, useState, useEffect, useMemo } from "react";
import { Circle, Line, Rect, Group, Text } from "react-konva";
import MapDeviceIcon from "../device/MapDeviceIcon";
import Gateway from "asset/mapComp/Gateway";
import DivExist from "components/DivExist";
import General from "@ecoenghk/general";
import moment from "moment";
import KonvaLabel from "../device/KonvaLabel";
import KonvaFaButton from "../device/KonvaFaButton";
import { espCheckUnicast } from "actions/gatewayActions";
import QuestionMark from "asset/mapComp/QuestionMark";
import { deviceConnected } from "actions/generalActions";
const gs = new General();

export default function LayerEspWeb({ layerProps }) {
    const {
        activeMapID,
        mapObjAll,
        lightObjAll,
        gatewayObjAll,
        sensorObjAll,
        daliCtlObjAll,
        mapScale,
        layerRef,
        iconSize,
        dispatch,
        espWebGatewayID,
        espWebMapMode,
        espCheckUnicastSender,
        espCheckUnicastReceiver,
        espUsability,
        espWebPathTargetDevice,
        espWebPathArray,
    } = layerProps;
    const gatewayID = espWebGatewayID;
    const gatewayObj = gatewayObjAll[gatewayID];
    const gatewayMac = gatewayObj?.ttyS7?.macAddress;
    const activeMapObj = mapObjAll[activeMapID];
    const { espNowRoute } = gatewayObj || {};
    const mapLightObj = activeMapObj?.lightObj;
    const mapSensorObj = activeMapObj?.sensorObj;
    const mapDaliCtlObj = activeMapObj?.daliCtlObj;
    const mapGatewayObj = activeMapObj?.gatewayObj;
    const espWebGwX = mapGatewayObj[espWebGatewayID]?.x * mapScale.x
    const espWebGwY = mapGatewayObj[espWebGatewayID]?.y * mapScale.y
    const coordinateObj = { ...mapLightObj, ...mapSensorObj, ...mapDaliCtlObj, ...mapGatewayObj };

    const [scale, setScale] = useState(2);
    const [deviceMeshData, setDeviceMeshData] = useState([]);
    const [macDeviceIdMap, setMacDeviceIdMap] = useState({});
    const size = iconSize * mapScale.x;
    useEffect(() => {
        const layer = layerRef.current;
        const layerScale = layer.scaleX();
        setScale(layerScale);
    }, []);
    useEffect(() => {
        dispatch({
            type: "SET_GENERAL_STATE",
            payload: { key: "espUsability", value: gatewayObj?.espUsability || {} }
        });
    }, [gatewayObj?.espUsability]);
    useEffect(() => {
        let data = [], map = {};
        if (gatewayID) {
            Object.keys(lightObjAll).forEach((s) => {
                const obj = lightObjAll[s];
                const macAdd = obj.wifiApMacAddress;
                if (macAdd) map[macAdd] = s;
                const espObj = espNowRoute?.[macAdd];
                let routeObj = {
                    deviceID: s,
                    serial: s,
                    deviceObj: obj,
                    deviceType: "light",
                    deviceStyle: obj.style,
                    x: mapLightObj[s]?.x * mapScale.x,
                    y: mapLightObj[s]?.y * mapScale.y,
                };
                if (espObj) {
                    const route = espObj?.["1"];
                    routeObj = {
                        ...routeObj,
                        wifiApMacAddress: macAdd,
                        distance: route?.distance,
                        path: route?.path,
                        length: route?.length
                    };
                }
                if (!obj.daliCtlID && !obj.masterSerial) data.push(routeObj);
            });
            Object.keys(sensorObjAll).forEach((s) => {
                const obj = sensorObjAll[s];
                const macAdd = obj.wifiApMacAddress;
                if (macAdd) map[macAdd] = s;
                const espObj = espNowRoute?.[macAdd];
                let routeObj = {
                    deviceID: s,
                    sensorID: s,
                    deviceObj: obj,
                    deviceType: "sensor",
                    deviceStyle: obj.type,
                    x: mapSensorObj[s]?.x * mapScale.x,
                    y: mapSensorObj[s]?.y * mapScale.y,
                };
                if (espObj) {
                    const route = espObj?.["1"];
                    routeObj = {
                        ...routeObj,
                        wifiApMacAddress: macAdd,
                        distance: route?.distance,
                        path: route?.path,
                        length: route?.length
                    };
                }
                data.push(routeObj);

            });
            Object.keys(gatewayObjAll).forEach((s) => {
                const obj = gatewayObjAll[s];
                const macAdd = obj.ttyS7?.macAddress;
                if (macAdd) map[macAdd] = s;
                const espObj = espNowRoute?.[macAdd];
                let routeObj = {
                    deviceID: s,
                    gatewayID: s,
                    deviceObj: obj,
                    deviceType: "gateway",
                    deviceStyle: "",
                    x: mapGatewayObj[s]?.x * mapScale.x,
                    y: mapGatewayObj[s]?.y * mapScale.y,
                };
                if (espObj && s !== gatewayID) {
                    const route = espObj?.["1"];
                    routeObj = {
                        ...routeObj,
                        wifiApMacAddress: macAdd,
                        distance: route?.distance,
                        path: route?.path,
                        length: route?.length
                    };
                }
                data.push(routeObj);
            });
            Object.keys(daliCtlObjAll).forEach((s) => {
                const obj = daliCtlObjAll[s];
                const macAdd = obj.wifiApMacAddress;
                if (macAdd) map[macAdd] = s;
                const espObj = espNowRoute?.[macAdd];
                let routeObj = {
                    deviceID: s,
                    daliCtlID: s,
                    deviceObj: obj,
                    deviceType: "daliCtl",
                    deviceStyle: "",
                    x: mapDaliCtlObj[s]?.x * mapScale.x,
                    y: mapDaliCtlObj[s]?.y * mapScale.y,
                };
                if (espObj) {
                    const route = espObj?.["1"];
                    routeObj = {
                        ...routeObj,
                        wifiApMacAddress: macAdd,
                        distance: route?.distance,
                        path: route?.path,
                        length: route?.length
                    };
                }
                data.push(routeObj);
            });
            setDeviceMeshData(data);
            setMacDeviceIdMap(map);
        }
    }
        , [lightObjAll, sensorObjAll, gatewayObjAll, daliCtlObjAll, gatewayID]);
    const clickUnicastCheck = (obj) => {
        if (gs.isEmptyJson(espCheckUnicastSender)) {
            dispatch({
                type: "SET_GENERAL_STATE",
                payload: { key: "espCheckUnicastSender", value: obj }
            });
            return;
        }
        if (gs.isEmptyJson(espCheckUnicastReceiver) && espCheckUnicastSender.deviceID !== obj.deviceID) {
            dispatch({
                type: "SET_GENERAL_STATE",
                payload: { key: "espCheckUnicastReceiver", value: obj }
            });
        }
    }
    const handleClickDevice = (obj) => {
        if (espWebMapMode === "route") {
            let newDeviceMeshData = [...deviceMeshData, obj];
            setDeviceMeshData(newDeviceMeshData);
            dispatch({
                type: "SET_GENERAL_STATE",
                payload: { key: "espWebDeviceID", value: obj.deviceID }
            })
        } else if (espWebMapMode === "check") {
            clickUnicastCheck(obj);
        } else if (espWebMapMode === "setPath") {
            if (!espWebPathTargetDevice || gs.isEmptyJson(espWebPathTargetDevice)) {
                console.log("set target device", obj);
                dispatch({
                    type: "SET_GENERAL_STATE",
                    payload: { key: "espWebPathTargetDevice", value: obj }
                })
            } else {
                const path = espWebPathArray || [];
                const newPath = [...path, obj];
                dispatch({
                    type: "SET_GENERAL_STATE",
                    payload: { key: "espWebPathArray", value: newPath }
                });
            }
        }
    }
    return (
        <>

            {
                (deviceMeshData || []).map((obj, key) => {
                    const deviceID = obj.deviceID;
                    if (deviceID === espWebGatewayID) return null;
                    const allPath = [gatewayMac, ...(obj.path || [])];
                    return (
                        <Fragment key={key}>
                            <DivExist show={espWebMapMode === "route"}>
                                <GatewayWebLineGroup
                                    obj={obj}
                                    macDeviceIdMap={macDeviceIdMap}
                                    coordinateObj={coordinateObj}
                                    scale={scale}
                                    allPath={allPath}
                                    layerProps={layerProps}
                                />
                            </DivExist>
                            <MeshDevice
                                obj={obj}
                                scale={scale}
                                layerProps={layerProps}
                                onClick={(e) => handleClickDevice(obj)}
                            />
                        </Fragment>
                    )
                })
            }
            {
                !espWebGatewayID ?
                    <>
                        {
                            Object.keys(gatewayObjAll).map((g, key) => {
                                return (
                                    <ActiveGatewayForSelect
                                        key={key}
                                        x={mapGatewayObj[g]?.x * mapScale.x}
                                        y={mapGatewayObj[g]?.y * mapScale.y}
                                        layerProps={layerProps}
                                        gatewayID={g} />
                                )
                            })
                        }
                    </>
                    :
                    <Group x={espWebGwX} y={espWebGwY}>
                        <Rect width={iconSize} height={iconSize} stroke="lightgreen" />
                        <Gateway shadEnabled={false} disabledConnectStatus size={size} />
                    </Group>
            }
            <DivExist show={espWebMapMode === "check"}>
                <UnicastComponents
                    gatewayID={espWebGatewayID}
                    sender={espCheckUnicastSender}
                    receiver={espCheckUnicastReceiver}
                    espUsability={espUsability}
                    size={size}
                    scale={scale}
                    layerProps={layerProps}
                    mapObj={activeMapObj}
                />
            </DivExist>
            <DivExist show={espWebMapMode === "setPath"}>
                <Rect x={espWebPathTargetDevice.x} y={espWebPathTargetDevice.y} width={iconSize * 1.2} height={iconSize * 1.2} stroke="red" />
                <Text x={espWebPathTargetDevice.x} y={espWebPathTargetDevice.y + (1.3 * iconSize)} text="Target device" fill="red" />
                {
                    (espWebPathArray || []).length === 0 ?
                        <Line points={[espWebGwX, espWebGwY, espWebPathTargetDevice.x, espWebPathTargetDevice.y]} stroke="#8E44AD" strokeWidth={2 / scale} />
                        :
                        <Line points={[espWebGwX, espWebGwY, espWebPathArray[0].x, espWebPathArray[0].y]} stroke="#8E44AD" strokeWidth={2 / scale} />
                }
                {
                    (espWebPathArray || []).map((obj, key) => {
                        const nextObj = espWebPathArray[key + 1];
                        return (
                            <Fragment key={key}>
                                <Rect key={key} x={obj.x} y={obj.y} width={iconSize * 1.2} height={iconSize * 1.2} stroke="#8E44AD" />
                                {
                                    nextObj ?
                                        <Line points={[obj.x, obj.y, nextObj.x, nextObj.y]} stroke="#8E44AD" strokeWidth={2 / scale} />
                                        :
                                        <Line points={[obj.x, obj.y, espWebPathTargetDevice.x, espWebPathTargetDevice.y]} stroke="#8E44AD" strokeWidth={2 / scale} />
                                }

                            </Fragment>
                        )
                    })
                }
            </DivExist>


        </>
    )
}

function ActiveGatewayForSelect({ x, y, layerProps, gatewayID }) {
    const { iconSize, mapScale, dispatch } = layerProps;
    const [hover, setHover] = useState(false);
    const onMouseOver = (e) => {
        setHover(true);
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";
    }
    const onMouseLeave = (e) => {
        setHover(false);
        const container = e.target.getStage().container();
        container.style.cursor = "default";
    }
    return (
        <Group x={x || 0} y={y || 0}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onClick={(e) => {
                e.evt.preventDefault();
                dispatch({
                    type: "SET_GENERAL_STATE",
                    payload: { key: "espWebGatewayID", value: gatewayID }
                })
            }}>
            <Rect
                width={iconSize * mapScale.x * 1.1}
                height={iconSize * mapScale.y * 1.1}
                stroke="#EB984E"

                fill={hover ? "yellow" : ""}
            />
            <Gateway shadEnabled={false} disabledConnectStatus size={iconSize * mapScale.x} />
        </Group>
    )

}
function MeshDevice({ obj, layerProps, onClick }) {
    const { mapScale, iconSize } = layerProps;
    const [hover, setHover] = useState(false);
    const onMouseOver = (e) => {
        setHover(true);
        const container = e.target.getStage().container();
        container.style.cursor = "pointer";
    }
    const onMouseLeave = (e) => {
        setHover(false);
        const container = e.target.getStage().container();
        container.style.cursor = "default";
    }
    const connected = deviceConnected(obj.deviceObj?.zigbeeConnected, obj.deviceObj?.timeStamp, true);
    const width = iconSize * mapScale.x * 1.1;
    const height = iconSize * mapScale.y * 1.1;
    const disableEspNow = obj.deviceObj.disableEspNow ? true : false
    return (
        <Group x={obj.x || 0} y={obj.y || 0}>
            {
                hover &&
                <Rect width={width} height={height} fill="yellow" />
            }
            <MapDeviceIcon
                deviceObj={obj.deviceObj}
                layerProps={layerProps}
                deviceType={obj.deviceType}
            />
            <Rect
                width={iconSize}
                height={iconSize}
                stroke={obj.path ? "lightgreen" : "pink"}
                onClick={onClick}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            />
            <Circle x={0} y={0} radius={0.1 * iconSize} fill={connected ? "green" : "red"} />
            {
                disableEspNow &&
                <Group x={0} y={height * 1.05}>
                    <Text fontSize={0.4 * iconSize} text="ESP" fill="red" />
                    <Line points={[0, 0, 0.8 * iconSize, 0.3 * iconSize]} stroke="red" strokeWidth={0.04 * iconSize} />
                </Group>
            }
        </Group>
    )
}
function GatewayWebLineGroup({ obj, macDeviceIdMap, coordinateObj, scale, allPath, layerProps }) {
    const { mapScale, iconSize, espWebDeviceID } = layerProps;
    const lineColor = obj.deviceID === espWebDeviceID ? "red" : "lightgreen";
    if (obj.path?.length === 0 || !obj.path) return null;
    return (
        <Fragment>
            <Group x={obj.x} y={obj.y}>
                {
                    (allPath).map((l, key1) => {
                        const macStart = l;
                        const deviceIdStart = macDeviceIdMap[macStart];
                        const sx = coordinateObj[deviceIdStart]?.x * mapScale.x - obj.x;
                        const sy = coordinateObj[deviceIdStart]?.y * mapScale.y - obj.y;
                        const macEnd = allPath[key1 + 1];
                        const deviceIdEnd = macDeviceIdMap[macEnd];
                        const ex = coordinateObj[deviceIdEnd]?.x * mapScale.x - obj.x;
                        const ey = coordinateObj[deviceIdEnd]?.y * mapScale.y - obj.y;
                        if (key1 < allPath?.length - 1) {
                            return (
                                <Line key={key1} points={[sx, sy, ex, ey]} stroke={lineColor} strokeWidth={2 / scale} />
                            )
                        }
                        return null;
                    })
                }
            </Group>
        </Fragment>
    )
}
function UnicastComponents({ gatewayID, sender, receiver, espUsability, size, scale, layerProps, mapObj }) {
    const uSenderID = sender.deviceID;
    const uReceiverID = receiver.deviceID;
    const uSenderX = sender.x || 0;
    const uSenderY = sender.y || 0;
    const uReceiverX = receiver.x || 0;
    const uReceiverY = receiver.y || 0;
    const m = moment((espUsability?.timeStamp || 0) * 1000);
    const usabilityIsNew = moment().diff(m, "minutes") < 10;
    const { startMac, endMac, duplex, connectionQuality, combinedDelay, channel } = espUsability || {};
    const correctMac = startMac === sender.wifiApMacAddress && endMac === receiver.wifiApMacAddress;
    const stageScale = mapObj.buttonSize / scale;
    const duplexSymbol = !connectionQuality ? "" : duplex ? "\u{01f501}" : "\u{01f500}";
    const delayText = combinedDelay ? `${combinedDelay}ms` : "";
    return (
        <>
            <DivExist show={uSenderID}>
                <Circle x={uSenderX + (0.5 * size)} y={uSenderY + (0.5 * size)} radius={size} stroke={'blue'} />
            </DivExist>
            <DivExist show={uReceiverID}>
                <Circle x={uReceiverX + (0.5 * size)} y={uReceiverY + (0.5 * size)} radius={size} stroke={"purple"} />
            </DivExist>
            <DivExist show={uSenderID && uReceiverID && usabilityIsNew && correctMac}>
                <Line points={[uSenderX, uSenderY, uReceiverX, uReceiverY]} stroke={'blue'} strokeWidth={2 / scale} />
                <KonvaLabel x={(uSenderX + uReceiverX) / 2} y={(uSenderY + uReceiverY) / 2} text={`${connectionQuality}% ${duplexSymbol} ${delayText}`} tagColor="darkblue" textColor="white" scale={scale} />
            </DivExist>
            <DivExist show={uSenderID && uReceiverID && !espUsability?.timeStamp}>
                <Line points={[uSenderX, uSenderY, uReceiverX, uReceiverY]} stroke={'grey'} strokeWidth={1.5 / scale} />
                <KonvaFaButton
                    x={(uSenderX + uReceiverX) / 2 / layerProps.mapScale.x}
                    y={(uSenderY + uReceiverY) / 2 / layerProps.mapScale.y}
                    onBtnClick={() => {
                        espCheckUnicast(gatewayID, sender, receiver, layerProps.dispatch);
                    }}
                    btnSize={size}
                    stageScale={stageScale}
                    mapScale={layerProps.mapScale}
                    iconSvg={<QuestionMark size={size} />}
                    tooltip={"Check unicast"}
                    tagColor="grey"
                />
            </DivExist>
        </>
    )
}
