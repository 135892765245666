import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Grid, Box } from "@mui/material";
import Divider10 from "components/Divider10";
import DaliCtl from "asset/svgComp/DaliCtl";
import DaliSlavesControlPanel from "./DaliSlavesControlPanel";
import ModalSM from "components/ModalSM";
import DaliCtlSuperAdminPanel from "./DaliCtlSuperAdminPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import { deviceConnected, gatewayConnected } from "actions/generalActions";
import DaliCtlDataLogPanel from "./DaliCtlDataLogPanel";
import DaliCtlInfoPanel from "./DaliCtlInfoPanel";
import DaliCtlStatusPanel from "./DaliCtlStatusPanel";
const boxSx = {
  padding: "0.5vw",
  margin: "1vh 0.3vw 1vh 0.3vw",
  border: "0.5px solid grey",
  borderRadius: "1vw",
  overflowY: "auto",
};
export default function DaliCtlDetailModal() {
  const [state, dispatch] = useContext(SMContext);
  const {
    daliCtlObjAll,
    activeDaliCtlID,
    gatewayObjAll,
    openDaliCtlDetailModal,
  } = state;
  const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
  const { description, gatewayID, zigbeeConnected } = daliCtlObj || {};
  const handleClose = () => {
    dispatch({ type: "CLOSED_DALICTL_DETAIL_MODAL" });
  };
  const gwConnected = gatewayConnected(gatewayObjAll[gatewayID]);
  const connected = deviceConnected(zigbeeConnected, daliCtlObj?.timeStamp, gwConnected);
  return (
    <ModalSM
      open={openDaliCtlDetailModal}
      onClose={handleClose}
      width="97vw"
      height="95vh"
      disableBottomClose
      modalTitle={`${description} [${activeDaliCtlID}]`}
      modalIcon={
        <DeviceMongoModal deviceObj={daliCtlObj}>
          <DaliCtl width={3} sizeUnit="vw" connectStatus={connected} />
        </DeviceMongoModal>
      }
    >
      <Grid container>
        <Grid item xs={4}>
          <Box sx={{ height: "82vh", ...boxSx }}>
            <DaliCtlInfoPanel />
            <Divider10 />
            <DaliCtlSuperAdminPanel handleClose={handleClose} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ height: "82vh", ...boxSx }}>
            <DaliCtlStatusPanel />
            <DaliCtlDataLogPanel />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ height: "82vh", ...boxSx }}>
            <DaliSlavesControlPanel />
          </Box>
        </Grid>
      </Grid>
    </ModalSM>
  );
}
