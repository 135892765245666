import { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { Done } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import { mapAddLight } from "actions/mapActions";
import IconButtonSave from "components/IconButtonSave";
import { mgFindOne, mgPost } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import DivExist from "components/DivExist";
import { daliAddLight } from "actions/daliCtlActions";
import { alertWindow } from "actions/screenActions";

const gs = new General();
export default function AddLightToMapPanel({ handleClose }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, mapObjAll, daliCtlObjAll } = state;
  const [serial, setSerial] = useState("");
  const [lightObj, setLightObj] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  const checkSerial = async () => {
    const mapLightObj = mapObjAll[activeMapID]?.lightObj;
    if (mapLightObj?.hasOwnProperty(serial)) {
      setErrorMsg(`Light Serial no. ${serial} already in this map.`);
    } else {
      const obj = await mgFindOne("serial", "serial", serial);
      if (obj.result === "fail") {
        const fsLightObj = await mgPost("getDocFS", {
          docPath: `SM_serial/${serial}`,
        });
        console.log("lightObj from firestore", fsLightObj);
        if (fsLightObj.result === "fail") {
          setErrorMsg(`Serial no. [${serial}] not exist in database`);
        } else {
          setErrorMsg("");
          setLightObj(fsLightObj);
        }
      } else {
        setErrorMsg("");
        setLightObj(obj);
      }
    }
  };
  const handleSave = async () => {
    const daliCtlID = lightObj.daliCtlID;
    let go = true;
    if (daliCtlID) {
      const daliCtlObj = daliCtlObjAll[daliCtlID];
      if (!daliCtlObj) {
        alertWindow(
          dispatch,
          "Light is under a dali controller that is not in this map, add fail"
        );
        go = false;
      }
      if (go) {
        await daliAddLight(daliCtlObj, lightObj.shortAdd);
        await gs.waitFor(2000);
      }
    }
    if (go) {
      await mapAddLight(activeMapID, serial, lightObj);
    }
    handleClose();
  };
  return (
    <>
      <Typography variant="h6">Add Light to map</Typography>
      <Divider10 />
      <SpaceBetweenInput
        title="Light serial no."
        data={serial}
        width="40%"
        onInput={(evt) => {
          setSerial(evt.target.value);
          setErrorMsg("");
        }}
      />
      <SpaceBetweenDiv
        title=""
        data={
          <Button onClick={checkSerial}>
            <Done />
          </Button>
        }
      />
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )}

      <Divider10 />
      {lightObj?.style && (
        <DivInline justifyContent="center">
          <LightIcon
            lightStyle={lightObj?.style}
            width={120}
            disabledConnectStatus
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "2vw",
            }}
          >
            <Typography variant="caption">Serial no</Typography>
            <Typography variant="subtitle1">{serial}</Typography>
            <Typography variant="caption">mapName</Typography>
            <Typography variant="subtitle1">{lightObj?.description}</Typography>
            <DivExist show={lightObj?.zigbeeAdd}>
              <Typography variant="caption">Address</Typography>
              <Typography variant="subtitle1">{lightObj?.zigbeeAdd}</Typography>
            </DivExist>
            <DivExist show={lightObj?.daliCtlID}>
              <Typography variant="caption">
                Under Dali controller ID
              </Typography>
              <Typography variant="subtitle1">{lightObj?.daliCtlID}</Typography>
            </DivExist>
          </div>
        </DivInline>
      )}
      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonSave onBtnClick={handleSave} disabled={!lightObj?.style} />
      </DivInline>
    </>
  );
}
