import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import { LockOpen, Lock } from "@mui/icons-material";
import moment from "moment";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SystemStart from "asset/svgComp/SystemStart";
import QuestionMark from "asset/svgComp/QuestionMark";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import LightBulb from "asset/svgComp/LightBulb";
import { Info, QueryStats } from "@mui/icons-material";
import Gateway from "asset/svgComp/Gateway";
import General from "@ecoenghk/general";
import TimeHistoryDataModal from "components/TimeHistoryDataModal";
import AdminContainer from "components/AdminContainer";
const gs = new General();
export default function DaliCtlHistoryItem({
    data,
    iconOnly,
    messageOnly,
}) {
    const [state] = useContext(SMContext);
    const [openJson, setOpenJson] = useState(false);
    let cc = data.commandCode;
    let timeImg = <QuestionMark />;
    let timeMessage = gs.dtkActionCmdToMsg(data);
    if ("senderMacAddress" in data) timeMessage += ` (esp)`;
    //general command to daliCtl
    if (cc >= 0x0a01 && cc <= 0x0aff) timeImg = <ZcGeneral />;
    //general query to daliCtl
    if (cc >= 0x0b01 && cc <= 0x0bbf) timeImg = <QueryStats sx={{ color: "#117A65" }} />;
    //general daliCtl report properties
    if (cc >= 0x0c01 && cc <= 0x0cff) timeImg = <Info sx={{ color: "#2E86C1" }} />;
    switch (cc) {
        case 0x0c07:
            timeMessage = "Gateway acknowledged command";
            timeImg = <Gateway disabledConnectStatus />;
            break;
        case 0x0c04:
            timeImg = <SystemStart />;
            break;
        case 0x0c06:
            timeImg = <LightBulb onoff={data.lightLv > 0 ? "on" : "off"} />;
            break;
        case 0x0c08:
            let s = "off";
            data.lightLvArray.forEach(lv => {
                if (lv > 0) s = "on";
            });
            timeImg = <LightBulb onoff={s} />;
            break;
        case 0x0c0b:
            if (data.lockLevel === 0 || data.locklevel === 5) timeImg = <LockOpen />;
            else timeImg = <Lock />;
            break;
        case 0x0c0c:
            let l = "lockOpen";
            data.lockLvArray.forEach(lv => {
                if (lv > 0 && lv < 5) l = "lock";
            });
            timeImg = l === "lock" ? <Lock sx={{ color: "red" }} /> : <LockOpen sx={{ color: "green" }} />;
            break;
    }

    const m = moment(data.timeStamp);
    const dataDate = m.format("YYYY-MM-DD");
    const dataTime = m.format("HH:mm:ss");
    if (iconOnly) return <div>{timeImg}</div>;
    else if (messageOnly) return <div>{timeMessage}</div>;
    else
        return (
            <>
                <TimelineItem onClick={() => { if (state.userObj.level < 1) setOpenJson(true) }}>
                    <TimelineOppositeContent>
                        <Typography variant="caption" color="textSecondary" >
                            {dataDate}
                            <br />
                            {dataTime}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot>{timeImg}</TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography variant="caption">{timeMessage}</Typography>
                    </TimelineContent>
                </TimelineItem>
                <AdminContainer>
                    <TimeHistoryDataModal open={openJson} json={data} handleClose={() => setOpenJson(false)} timeImg={timeImg} />
                </AdminContainer>
            </>
        );
}
