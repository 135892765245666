import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { Typography, Box, Button, Tooltip } from "@mui/material";
import Divider10 from "components/Divider10";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryGatewayOneSetting, gatewayGroupActionCheck } from "actions/gatewayActions";
import General from "@ecoenghk/general";
import DeviceIcon from "components/DeviceIcon";
import ModalSM from "components/ModalSM";
import DivExist from "components/DivExist";
import JsonModalContainer from "components/JsonModalContainer";
import DeviceCmdMsgModal from "components/DeviceCmdMsgModal";
import ButtonSM from "components/ButtonSM";
import { UnfoldLess, UnfoldLessDouble, UnfoldMore, UnfoldMoreDouble, Workspaces } from "@mui/icons-material";

const gs = new General();

export default function BindedSensorSettingPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { activeGatewayID, gatewayObjAll, lightObjAll, sensorObjAll } = state;
  const [unfoldAll, setUnfoldAll] = useState(false);

  const gatewayObj = gatewayObjAll[activeGatewayID];
  let bindedSensorSetting = gatewayObj.bindedSensorSetting || {};
  delete bindedSensorSetting.timeStamp;
  const [bindedSensorSettingState, setBindedSensorSettingState] = useState({});
  const [bindedZcSettingState, setBindedZcSettingState] = useState({});
  const sortSetting_sensor = (setting_sensor, sortType) => {
    const settingByDeviceByNum = {};
    for (const key in setting_sensor) {
      const arr = key.split("_");
      const deviceID = arr[3];
      const deviceType = arr[2];
      const settingNum = String(arr[1]);
      const settingContent = setting_sensor[key];
      let cmdObj;
      if (typeof settingContent === "string") {
        cmdObj = JSON.parse(settingContent || "{}");
      } else {
        cmdObj = {
          ...settingContent,
          commandCode: Number(settingContent.commandCode),
        };
        if ("delaySec" in settingContent) cmdObj.delaySec = Number(settingContent.delaySec);
        if ("delaySec2" in settingContent) cmdObj.delaySec2 = Number(settingContent.delaySec2);
        if ("delayTime" in settingContent) cmdObj.delayTime = Number(settingContent.delayTime);
        if ("lockLevel" in settingContent) cmdObj.lockLevel = Number(settingContent.lockLevel);
        if ("releaseOnDelayEnd" in settingContent) cmdObj.releaseOnDelayEnd = Number(settingContent.releaseOnDelayEnd);
        if ("onoffcontrolA" in settingContent) cmdObj.onoffcontrolA = Number(settingContent.onoffcontrolA);
        if ("pwm" in settingContent) cmdObj.pwm = Number(settingContent.pwm);
        if ("delayedPwm" in settingContent) cmdObj.delayedPwm = Number(settingContent.delayedPwm);
        if ("delayedPwm2" in settingContent) cmdObj.delayedPwm2 = Number(settingContent.delayedPwm2);
        if ("disabledSensor" in settingContent) cmdObj.disabledSensor = Number(settingContent.disabledSensor);
        if ("stepUp" in settingContent) cmdObj.stepUp = Number(settingContent.stepUp);
        if ("dimStepSize" in settingContent) cmdObj.dimStepSize = Number(settingContent.dimStepSize);
      }
      if (deviceType === sortType) {
        if (deviceID in settingByDeviceByNum) {
          let settingByNum = settingByDeviceByNum[deviceID];
          if (settingNum in settingByNum) {
            settingByNum[settingNum].push(cmdObj);
          } else {
            settingByNum[settingNum] = [cmdObj];
          }
          settingByDeviceByNum[deviceID] = settingByNum;
        }
        else {
          settingByDeviceByNum[deviceID] = { [settingNum]: [cmdObj] };
        }
      }
    }
    return settingByDeviceByNum;
  }
  useEffect(() => {
    if (bindedSensorSetting && gs.isEmptyJson(bindedSensorSetting) === false) {
      const settingObj = bindedSensorSetting?.setting_sensor || {};
      setBindedSensorSettingState(sortSetting_sensor(settingObj, "sensor"));
      setBindedZcSettingState(sortSetting_sensor(settingObj, "zc"));
    }
  }, [bindedSensorSetting]);
  return (
    <div>
      <DivInline justifyContent="center">
        <JsonModalContainer jsonData={bindedSensorSetting} >
          <Typography variant="h6" align="center" >Sensor settings </Typography>
        </JsonModalContainer>
        <IconButtonRefresh
          onBtnClick={() => queryGatewayOneSetting(gatewayObj, 0x000d)}
        />
        <ButtonSM onClick={() => setUnfoldAll(true)} tooltip="expand all">
          <UnfoldMoreDouble sx={{ fontSize: "1rem" }} />
        </ButtonSM>
        <ButtonSM onClick={() => setUnfoldAll(false)} tooltip="fold all">
          <UnfoldLessDouble sx={{ fontSize: "1rem" }} />
        </ButtonSM>
      </DivInline>
      <Divider10 />
      {/* <div>{Object.keys(bindedSensorSettingState || {}).join(",")}</div> */}
      <Divider10 />
      {
        Object.keys(bindedSensorSettingState || {}).map((sensorID, index) => {
          const settingByNum = bindedSensorSettingState[sensorID];
          return (
            <SettingSection key={index} controllerID={sensorID} controllerType="sensor" settingByNum={settingByNum} unfoldAll={unfoldAll} />
          )
        })
      }
      <Divider10 />
      {/* <div>{Object.keys(bindedZcSettingState || {}).join(",")}</div> */}
      <Divider10 />
      {
        Object.keys(bindedZcSettingState || {}).map((zoneControlID, index) => {
          const settingByNum = bindedZcSettingState[zoneControlID];
          return (
            <SettingSection key={index} controllerID={zoneControlID} controllerType="zoneControl" settingByNum={settingByNum} unfoldAll={unfoldAll} />
          )
        })
      }
      <Divider10 />
    </div>
  );
}

function SettingSection({ controllerID, controllerType, settingByNum, unfoldAll }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeGatewayID } = state;
  const { lightObjAll, sensorObjAll, zoneControlObjAll } = state;
  const controllerObj = controllerType === "sensor" ? sensorObjAll[controllerID] : zoneControlObjAll[`zc_${controllerID}`];
  const [fold, setFold] = useState(true);
  const [allGroupActionConfirmed, setAllGroupActionConfirmed] = useState(false);
  useEffect(() => {
    setFold(!unfoldAll);
  }, [unfoldAll]);
  useEffect(() => {
    if (settingByNum) {
      let confirmed = true;
      for (const settingNum in settingByNum) {
        for (const cmdObj of settingByNum[settingNum]) {
          if (cmdObj.confirmed != "1") {
            confirmed = false;
            break;
          }
        }
        if (confirmed === false) break;
      }
      setAllGroupActionConfirmed(confirmed);
    }
  }, [settingByNum]);
  const handleGroupActionCheck = () => {
    gatewayGroupActionCheck(activeGatewayID);
  }
  return (
    <Box >
      <Divider10 />
      <DivInline justifyContent="center">
        {/* <JsonModalContainer jsonData={settingByNum} disabled={state.userObj?.level > 0}> */}
        <Box onClick={() => console.log(settingByNum)}>

          <DeviceIcon deviceType={controllerType} width={2} deviceStyle={controllerObj?.type} />
        </Box>
        {/* </JsonModalContainer> */}
        <Typography variant="body2">{controllerID}</Typography>
        {controllerObj?.enableGroupAction && <Workspaces color={allGroupActionConfirmed ? "success" : "error"} onClick={handleGroupActionCheck} />}
        <ButtonSM onClick={() => setFold(!fold)} tooltip={fold ? "Expand" : "Fold"}>
          {
            fold ? <UnfoldMore sx={{ fontSize: "1rem" }} /> : <UnfoldLess sx={{ fontSize: "1rem" }} />
          }
        </ButtonSM>
      </DivInline>
      <Divider10 />
      <DivExist show={!fold}>
        {
          Object.keys(settingByNum || {}).map((settingNum, key) => {
            return (
              <Box key={key}>
                <Typography variant="body2">{`Setting ${settingNum}`}</Typography>
                {
                  settingByNum[settingNum].map((cmdObj, index) => {
                    const deviceID = cmdObj.serial || cmdObj.sensorID || cmdObj.daliCtlID;
                    let newCmdObj = { ...cmdObj };
                    if ("lightLvAry" in cmdObj && typeof cmdObj.lightLvAry === "string") {
                      const newLightLvAryObj = cmdObj.lightLvAry.includes("{") ? JSON.parse(cmdObj.lightLvAry) : cmdObj.lightLvAry.split(",");
                      const newLightLvAry = Object.values(newLightLvAryObj);
                      newCmdObj = { ...newCmdObj, lightLvAry: newLightLvAry };
                    }
                    if ("D1LightLvAry" in cmdObj && typeof cmdObj.D1LightLvAry === "string") {
                      const newD1LightLvAryObj = cmdObj.D1LightLvAry.includes("{") ? JSON.parse(cmdObj.D1LightLvAry) : cmdObj.D1LightLvAry.split(",");
                      const newD1LightLvAry = Object.values(newD1LightLvAryObj);
                      newCmdObj = { ...newCmdObj, D1LightLvAry: newD1LightLvAry };
                    }
                    if ("D2LightLvAry" in cmdObj && typeof cmdObj.D2LightLvAry === "string") {
                      const newD2LightLvAryObj = cmdObj.D2LightLvAry.includes("{") ? JSON.parse(cmdObj.D2LightLvAry) : cmdObj.D2LightLvAry.split(",");
                      const newD2LightLvAry = Object.values(newD2LightLvAryObj);
                      newCmdObj = { ...newCmdObj, D2LightLvAry: newD2LightLvAry };
                    }
                    const cmdMsg = gs.dtkActionCmdToMsg(newCmdObj, deviceID);
                    // const cmdMsg = JSON.stringify(newCmdObj);
                    const deviceType = cmdObj.serial ? "light" : cmdObj.sensorID ? "sensor" : cmdObj.daliCtlID ? "daliCtl" : "";
                    const deviceStyle = cmdObj.serial ? lightObjAll[cmdObj.serial]?.style : cmdObj.sensorID ? sensorObjAll[cmdObj.sensorID]?.type : "";
                    const confirmed = cmdObj.confirmed == "1" ? true : false;
                    return (
                      <DivInline
                        key={index}
                        onClick={() => console.log(cmdObj)}
                      >
                        {
                          controllerObj?.enableGroupAction && <Workspaces color={confirmed ? "success" : "error"} onClick={handleGroupActionCheck} />
                        }
                        <DeviceIcon
                          deviceType={deviceType}
                          width={1.4}
                          deviceStyle={deviceStyle}
                          gang={sensorObjAll[cmdObj.sensorID]?.gang}
                        />
                        <DeviceCmdMsgModal deviceType={deviceType} deviceID={deviceID} cmdObj={cmdObj} disableOpen={state.userObj.level > 0} >
                          <Typography variant='caption'>{cmdMsg}</Typography>
                        </DeviceCmdMsgModal>
                      </DivInline>
                    );
                  })
                }
              </Box>
            )
          })
        }
      </DivExist>
    </Box>
  )
}