import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { ArrowBack, ArrowForward, Lock } from "@mui/icons-material";
import { Button, Typography, Checkbox } from "@mui/material";
import DivCol from "components/DivCol";
import ModalSM from "components/ModalSM";
import DivExist from "components/DivExist";
import { saveZcLockLevel } from "actions/zcActions";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";

export default function ChangeLockLvScenebtnModal({
  zoneControlObj,
  existingLv,
  existingRelease,
}) {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [lockLevel, setLockLevel] = useState(5);
  const [releaseOnDelayEnd, setReleaseOnDelayEnd] = useState(0);
  const handleOpen = () => {
    setOpen(true);
    setLockLevel(existingLv);
    setReleaseOnDelayEnd(existingRelease);
  };
  const handleClose = () => {
    setOpen(false);
    setLockLevel(5);
    setReleaseOnDelayEnd(0);
  };
  const handleSave = async () => {
    dispatch({
      type: "UNCONFIRM_ZONE_CONTROL_GAS_SETTING",
      payload: {
        zoneControlObj,
        settingNumArray: [1]
      }
    });
    await saveZcLockLevel(
      zoneControlObj.zoneControlID,
      zoneControlObj,
      [1],
      [lockLevel],
      [releaseOnDelayEnd]
    );
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" size="small">
        <Lock sx={{ fontSize: "1.2em" }} />
        {existingLv > 5 ? 5 : existingLv}
      </Button>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle="Change priority level"
        onSave={handleSave}
      >
        <DivInline justifyContent="space-between">
          {[5, 4, 3, 2, 1].map((n) => {
            let variant = n === lockLevel ? "contained" : "outlined";
            return (
              <Button key={n} variant={variant} onClick={() => setLockLevel(n)}>
                <Lock />
                {n}
              </Button>
            );
          })}
        </DivInline>
        <DivInline sx={{ marginTop: "1vh" }} justifyContent="space-around">
          <DivCol>
            <ArrowBack />
            <Typography>Low priority</Typography>
          </DivCol>
          <DivCol>
            <ArrowForward />
            <Typography>High priority</Typography>
          </DivCol>
        </DivInline>
        <Divider10 />
        <SpaceBetweenDiv
          title="Release light to lowest priority on action end"
          data={
            <Checkbox
              checked={releaseOnDelayEnd === 1 ? true : false}
              onChange={(e) => setReleaseOnDelayEnd(e.target.checked ? 1 : 0)}
            />
          }
        />
      </ModalSM>
    </>
  );
}
